@import 'css/variables';

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid $c-gray50;

  th,
  td {
    padding-left: $size24;
    padding-right: $size24;
    border-right: 0.5px solid $c-gray50;

    @media (max-width: 640px) {
      padding-left: $size16;
      padding-right: $size16;
    }
  }
}

.head {
  font-size: $size11;
  line-height: $size18;
  color: $c-gray900;
  font-weight: 500;
  text-align: left;
}

.row {
  height: $size24;
  text-align: left;
  border-top: 0.5px solid $c-gray50;
  font-size: $size11;
  line-height: $size18;
  font-weight: 400;
  color: $c-gray800;
}

.oddRow {
  background-color: $c-gray50;
}

.noCell {
  width: $size16;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.blockchainCell {
  text-align: left;
}

.tgGrowthIndexCell {
  text-align: center;
}

.cellContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size6;
  white-space: nowrap;
}

.logo {
  width: auto;
  height: $size10;
}

.sortableHeader {
  cursor: pointer;
}
