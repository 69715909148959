@import 'css/variables';

.msgContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;

  p {
    width: 100%;
  }
}

.test {
  margin-bottom: 0;
  padding: $size12;
  border-radius: $radius;
}
