@import 'css/variables';

.container {
  width: $size393;

  @media (max-width: 640px) {
    width: 100%;
  }

  > button {
    margin-top: $size16;
  }
}

.inviteSettingsContainer {
  @media (max-width: $mobile) {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: $size12;
  }
}

.accessTypeSettingsContainer {
  width: 50%;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.usersWithAccessContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
}

.userAccessItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
}

.userEmail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: $size14;
    line-height: $size20;
    color: $c-gray700;
    margin-top: $size2;
    padding-top: $size2;
    padding-left: $size8;
  }

  img {
    height: $size16;
    width: auto;
    margin: 0;
  }
}

.actionBox {
  padding: 5px 10px;
  background-color: $c-off-white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.actionBox.remove {
  color: #d33;
}

.actionBox:hover {
  background-color: #e5e5e5;
}

.box {
  padding: 5px 10px;
  background-color: $c-off-white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.userName {
  color: $c-primary800;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.userEmailNoMargin {
  color: $c-gray700;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
