@import 'css/variables';

.base {
  display: block;
  height: auto;
  margin: 0;
  outline: none;

  @media (max-width: $tablet) {
    width: 8rem;
  }
}
