@import 'css/variables';

.base {
  font-size: $size20;
  line-height: $size30;
  font-weight: 600;
  color: $c-primary800;
}

.small {
  font-size: $size16;
  line-height: $size24;
}

.xsmall {
  font-size: $size14;
  line-height: $size20;
  font-weight: 500;
}

.darkMode {
  color: $c-white;
}
