@import 'css/variables';

.base {
  position: absolute;
  left: 50%;
  top: 50%;
  width: $size22;
  height: $size6;
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $c-bg;
  z-index: 50000000;
  font-size: $size18;
  color: $c-primary800;
}

.orbit {
  $speed: 1s;
  position: absolute;
  z-index: 10;
  -webkit-animation: rotate $speed infinite linear;
  -moz-animation: rotate $speed infinite linear;
  -o-animation: rotate $speed infinite linear;
  animation: rotate $speed infinite linear;
}
