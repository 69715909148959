@import 'css/variables';

.shareLinkContainer {
  margin-top: $size16;
  display: grid;
  grid-template-columns: auto $size100;
  grid-template-rows: auto;
  gap: $size8;

  @media (max-width: 480px) {
    grid-template-columns: auto;
  }

  > button {
    margin-bottom: $size16;
    align-self: end;
  }
}
