/* @import 'css/variables'; */

.icon-button {
  border: none;
  text-decoration: none;
  cursor: pointer;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
}
