@import 'css/variables';

.insights {
  background-color: #fff8e1;
  padding: $size24;
  border-radius: $radius;
  margin-top: $size24;
  margin-bottom: $size24;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $size16;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
  }

  .cardTitleContainer {
    display: flex;
    align-items: center;
    gap: $size8;
  }

  .buttonShare {
    padding: $size2 $size8;
    font-size: $size12;
    line-height: $size18;
    height: $size24;
    border: 1px solid $c-gray100;
    border-radius: $radius;
    display: flex;
    align-items: center;
    gap: $size4;
    white-space: nowrap;
  }

  .insightCard {
    background: #fff;
    border-radius: $radius;
    padding: 16px;
    margin-bottom: $size16;
    border: 1px solid $c-gray75;

    .cardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $size12;

      .cardTitle {
        font-weight: 600;
        font-size: $size16;
        line-height: $size24;
        color: $c-gray900;
        margin: 0;
      }
    }

    .content {
      font-size: $size16;
      line-height: $size26;
      margin-bottom: $size24;
      color: $c-gray800;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  gap: $size8;
  align-items: center;
}

.insightSettings {
  display: flex;
  justify-content: space-between;
  gap: $size8;
}
