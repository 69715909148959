@import 'css/variables';

.contentContainer {
  width: $size160;
  margin: $size12 0 $size14 0;
}

.dropDownContentTitle {
  font-size: $size14;
  line-height: $size20;
  font-weight: 500;
  color: $c-gray900;
}

.sortButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;
  font-size: $size14;
  line-height: $size20;
  font-weight: 400;
  padding: $size6 $size6;
  width: 100%;
}

.boldText {
  font-weight: 500;
}

.activeButton {
  border: 1px solid $c-gray100;
  border-radius: $radius;
  background-color: $c-gray50;
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  gap: $size8;
  margin-bottom: $size16;
}
