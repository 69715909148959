@import 'css/variables';

.tableContainer {
  width: 100%;
  overflow-x: auto;
  min-height: $size450;
}

.topUsers {
  width: 100%;
  position: relative;
  overflow-x: auto;
  border: 1px solid $c-gray75;
  border-radius: $size8;
  margin-bottom: $size48;
}

.usersTable {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.head {
  font-size: $size12;
  line-height: $size18;
  color: $c-gray600;
  font-weight: 500;
  height: $size72;

  th {
    padding: $size11 $size24;
    white-space: nowrap;
  }
}

.row {
  height: $size72;
  border-top: 1px solid #eaecf0;
  font-size: $size16;
  line-height: $size24;
  color: $c-gray800;
  cursor: pointer;

  &:hover {
    background-color: $c-gray50;
  }
}

.activeRow {
  background-color: $c-gray75;
}

.listOfAdresses {
  list-style-type: none;
  display: flex;
  gap: $size12;
}

.column-container {
  margin: 0 2.4rem;
  height: 7rem;
  display: flex;
  align-items: center;
}

.addressText {
  color: $c-primary500;
  display: flex;
  align-items: baseline;
  gap: $size4;
  font-size: 1.4rem;
  line-height: 2rem;
  min-width: 36rem;
  width: 35%;

  @media (max-width: $mobile) {
    min-width: auto;
    width: auto;
  }
}

.headCellContainer {
  display: flex;
  align-items: center;
  gap: $size8;
  cursor: pointer;

  svg {
    pointer-events: none;
  }
}

.nameLabel {
  font-size: $size14;
  line-height: $size20;
  color: $c-gray500;
  font-weight: 400;
}

.interactionBadge {
  border-radius: $radius;
  border: 1px solid $c-gray500;
  background-color: $c-gray50;
  padding: $size2 $size8;
  display: inline-block;
  font-size: $size14;
  line-height: $size20;
  color: $c-gray900;
}

.other-dapps-container {
  width: $size160;
  margin: $size12 0 $size14 0;
}

.blockchain-container {
  width: $size120;
  margin: $size12 0 $size14 0;
}
