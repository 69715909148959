@import 'css/variables';

.tgGrowthLegendItem {
  text-align: center;
  min-width: $size76;
}

.icon {
  margin: auto;
  margin-bottom: $size16;
}

.stripe {
  height: $size6;
  border-radius: $size2;
  width: 100%;
}

.low {
  background-color: $c-red500;
}

.low-mid {
  background-color: #fc8e3e;
}

.middle {
  background-color: #fcc53e;
}

.mid-hight {
  background-color: $c-green200;
}

.hight {
  background-color: #22a957;
}

.scope {
  margin-top: $size8;
  color: $c-primary500;
  font-size: $size14;
  line-height: $size20;
}

.tooltipContainer {
  width: $size200;
  text-align: left;
}

.tooltipScopeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $size4;
}

.tooltipScope {
  color: $c-gray700;
  font-weight: 600;
  padding-left: $size8;
  color: $c-gray800;
  font-size: $size14;
  line-height: $size20;
}

.tooltipInfo {
  color: $c-gray700;
  font-size: $size14;
  line-height: $size20;
}
