@import 'css/variables';

.input {
  position: relative;
  margin: 0 0 $size16;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  ::-webkit-input-placeholder {
    color: $c-gray500;
    font-size: $size16;
    line-height: $size24;
  }

  ::-moz-placeholder {
    color: $c-gray500;
    font-size: $size16;
    line-height: $size24;
  }

  :-ms-input-placeholder {
    color: $c-gray500;
    font-size: $size16;
    line-height: $size24;
  }

  :-moz-placeholder {
    color: $c-gray500;
    font-size: $size16;
    line-height: $size24;
  }
}

.textbox {
  display: block;
  position: relative;
  width: 100%;
  color: $c-gray900;
  font-size: $size16;
  padding: $size8;
  border-radius: $radius;
  background-color: $c-white;
  border: 1px solid $c-gray200;
  -webkit-appearance: none;
  -webkit-border-radius: $radius;

  &:focus {
    outline: none;
    border: 1.2px solid $c-green500;
    box-shadow: 0px 2px 3px 0px #1018200d;
    background-color: $c-white;
  }

  &:active {
    outline: none;
    border: 1.2px solid $c-green500;
    box-shadow: 0px 2px 3px 0px #1018200d;
    background-color: $c-white;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.textarea {
  min-height: $scale10;
  font-family: 'Roboto', helvetica, arial, sans-serif;
}

.input-container {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
  }
}

.iconSpace {
  padding-left: $size32;
  position: relative;
}

.icon {
  position: absolute;
  left: $size10;
  top: 50%;
  transform: translate(0, -50%);
}

.success {
  border-color: lighten($c-green, 3%);
}

.error {
  position: relative;
  margin-bottom: 0;
  color: $c-gray900;
  border: 1px solid $c-red500;
  background-color: lighten($c-red, 38%);

  ::-webkit-input-placeholder {
    color: $c-red500;
  }

  ::-moz-placeholder {
    color: $c-red500;
  }

  :-ms-input-placeholder {
    color: $c-red500;
  }

  :-moz-placeholder {
    color: $c-red500;
  }
}

.warning {
  background: darken($c-orange, 3%);
}
