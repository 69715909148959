@import 'css/variables';

@media (min-width: $mobile) {
  .nav-app-title {
    color: $c-primary500;
    font-size: $size16;
    line-height: $size20;
    font-weight: 600;
    text-align: left;
  }

  .nav-links {
    padding: 0;
    margin: $size15 0;

    .link-tile {
      margin: $size8 0;
      padding: $size12 $size16 $size12 $size8;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: $size8;
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      line-height: $size20;
    }

    .align-arrow {
      justify-content: space-between;
    }

    .icon {
      opacity: 1;
      transition: all 0.2s ease-out;
      height: $size16;
    }

    .label {
      font-size: $size14;
      line-height: $size20;
      color: $c-primary500;
    }

    .active {
      color: $c-primary;
      background-color: $c-gray100;
      border-radius: $radius;

      span {
        color: $c-primary;
      }
    }
  }

  .bottom {
    margin-bottom: 0;
  }

  .fade {
    animation: fade 0.4s linear;
  }

  .fadeIn {
    animation: fadeIn 0.4s linear;
  }

  .fadeOut {
    animation: fadeOut 0.4s linear;
  }
}

@media (max-width: $mobile) {
  .nav-app-title {
    margin-top: $size20;
    color: $c-primary500;
    font-size: $size16;
    line-height: $size24;
    font-weight: 600;
    text-align: left;
  }

  nav.nav-links {
    padding: $size8 0;

    a,
    div {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      float: none;
      // color: $c-white;
      color: $c-primary500;
      padding: $size16 0;
      width: 100% !important;
      font-size: $size16;
      line-height: $size24;
      text-decoration: none;

      svg {
        display: inline-block;
        height: $size16;
        margin-left: $size8;
      }

      .label {
        position: absolute;
        left: $size36;
      }

      &.active {
        color: $c-primary;
        background-color: $c-gray100;
        border-radius: $radius;
      }

      span {
        &.open {
          opacity: 0.5 !important;
        }
      }
    }
  }

  .fade {
    animation: fade 0.6s linear;
  }

  .fadeIn {
    animation: fadeIn 0.4s linear;
  }

  .fadeOut {
    animation: fadeOut 0.4s linear;
  }
}
