.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.wrapper span {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding: 0 1rem;
}

.icon {
  display: block;
  color: var(--color-gray900);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: left;
  background-color: var(--color-white);
}

.list {
  position: absolute;
  background-color: var(--color-white);
  border-radius: 0.6rem;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 32, 0.08),
    0px 4px 6px -2px rgba(16, 24, 32, 0.03);
  border: 1px solid var(--color-gray100);
  min-width: 12rem;
  text-align: left;
  z-index: 2;
  width: 24.7rem;
  max-height: 30rem;
  overflow-y: auto;
}

.bottom-right {
  top: 3.2rem;
  right: 0;
}

.bottom {
  top: 3.2rem;
}

.option {
  padding: 0.4rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  white-space: nowrap;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  cursor: pointer;
  margin-left: 0.8rem;
}

.selected {
  background-color: var(--color-gray50);
}

.danger {
  color: var(--color-danger600);
}

.primary-dot {
  height: 0.6rem;
  width: 0.5rem;
  background-color: var(--color-primary500);
  border-radius: 50%;
  display: inline-block;
}

.secondary-dot {
  height: 0.6rem;
  width: 0.6rem;
  background-color: var(--color-green500);
  border-radius: 50%;
  display: inline-block;
}

.eventcall-option {
  display: grid;
  grid-template-columns: 0.6rem auto 1.6rem;
  gap: 0.8rem;
  align-items: center;
  cursor: pointer;
  height: 3.6rem;
}

.selected {
  background-color: var(--color-gray50);
}

.danger {
  color: var(--color-danger600);
}
