@import 'css/variables';

.container {
  display: grid;
  align-items: center;
  grid-gap: $size28;
  grid-template-columns: 320px 1px calc(100% - 320px - 57px);
  grid-template-rows: 324px;
  width: 100%;
  justify-content: stretch;

  @media (max-width: $mobile) {
    grid-template-columns: 100%;
    grid-template-rows: 324px 324px;
  }
}
