.table-of-dapps {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2.4rem;
}

.table-of-dapps th {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-gray600);
  padding: 1.2rem 2.4rem 1.2rem 1.6rem;
}

.table-of-dapps td {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.6rem;
}

@media (max-width: 1024px) {
  .table-of-dapps td:first-child,
  .table-of-dapps th:first-child {
    position: sticky;
    left: 0;
    background-color: var(--color-white);
    z-index: 1;
  }

  .table-of-dapps td:first-child:before,
  .table-of-dapps th:first-child:before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0.2rem;
    display: block;
    height: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0.1rem 0rem 0rem;
  }
}

.table-of-dapps tr {
  border-bottom: 1px solid #eaecf0;
}

.dapp-column-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
}

.dapp-column-icon {
  width: 3.2rem;
  height: 3.2rem;
}

.blockchain-cell {
  min-width: 15rem;
}

@media (max-width: 1024px) {
  .blockchain-cell {
    min-width: auto;
  }
}

.blockchain-label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  padding: 0.6rem 0.8rem;
  border: 1px solid var(--color-gray100);
  border-radius: var(--radius);
  width: fit-content;
}

.blockchain-label-icon {
  width: 1.6rem;
  height: 1.6rem;
}

.blockchain-label-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-gray900);
  white-space: nowrap;
}

.status-label {
  padding: 0.2rem 0.8rem;
  border-radius: var(--radius);
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-gray900);
  white-space: nowrap;
}

.status-label-rest {
  composes: status-label;
  border: 1px solid var(--color-primary500);
  background-color: var(--color-primary50);
}

.status-label-running {
  composes: status-label;
  border: 1px solid var(--color-primary500);
  background-color: var(--color-primary50);
}

.status-label-created {
  composes: status-label;
  border: 1px solid var(--color-yellow500);
  background-color: var(--color-warning50);
}

.status-label-stopped {
  composes: status-label;
  border: 1px solid var(--color-danger500);
  background-color: var(--color-danger50);
}

.settings-container {
  position: relative;
}
