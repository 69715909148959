@import 'css/variables';

.sidebar {
  @media (min-width: $mobile) {
    position: fixed;
    width: $sidebarWidth;
    height: 100%;
    z-index: 2;
    padding: $size24;
    text-align: center;
    margin: auto;
    overflow: hidden;
    background-color: $c-gray50;
    transition: all 0.2s ease-out;
    border-right: 0.1rem solid $c-gray100;

    section.menu {
      display: flex;
      flex-direction: column;
      height: calc(100vh - $headerHeight - $size24); // 24px = bottom margin
      margin-top: $size24;
      justify-content: space-between;
    }
  }
}

.sidebar-mobile {
  @media (min-width: $mobile) {
    .btn-togglenav {
      display: none !important;
    }
  }

  @media (max-width: $mobile) {
    position: relative;
    background-color: $c-gray50;
    z-index: 6;

    .bar-wrapper {
      border-bottom: 1px solid $c-gray200;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $size24;
      height: $size80;
      background-color: $c-gray50;
      position: relative;
      z-index: 9;

      // logo
      img {
        width: auto;
        height: $size40;
        margin: $size20 0;
      }
    }

    .btn-togglenav {
      position: relative;
      z-index: 6;
    }

    &.open {
      position: fixed;
      width: 100%;
      min-height: 100vh;
      overflow-y: scroll;

      .menu {
        // display: block !important;
        display: grid !important;
      }
    }

    &.close {
      .menu {
        display: block !important;
        animation-name: slide-up;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
    }

    section.menu {
      position: absolute;
      margin-top: $size80;
      padding: 0 $size24 $size24 $size24;
      min-height: calc(100vh - 13.4rem);
      z-index: 6;
      top: 0;
      left: 0;
      right: 0;
      background-color: $c-gray50;
      animation-name: slide-down;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    .bottom-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
