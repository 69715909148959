@import 'css/variables';

.section {
  margin-top: $size12;
  margin-bottom: $size24;
  display: flex;
  justify-content: space-between;
  gap: $size16;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: $size12;
  }
}

.inputs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size16;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
}
