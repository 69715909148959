.search-input {
  border: 0.1rem solid var(--color-gray200);
  border-radius: var(--radius);
  color: var(--color-gray900);
  width: 100%;
}

.search-input::placeholder {
  color: var(--color-gray500);
}

.search-input:focus {
  outline: 1px solid var(--color-green500);
}

.small {
  height: 3.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.8rem 1.2rem;
}

.with-icon {
  padding: 0.8rem 1.2rem 0.8rem 3.4rem;
}

.search-input-container {
  position: relative;
}

.search-input-container svg {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translate(0, -50%);
}
