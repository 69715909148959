@import 'css/variables';

.column {
  text-align: center;
  align-self: center;
  justify-self: end;

  @media (max-width: $mobile) {
    display: none;
  }
}

.headline {
  font-size: $size18;
  line-height: $size28;
  font-weight: 600;
  color: $c-gray900;
  text-align: center;
  margin: 1.6rem auto 0.8rem auto;
}

.text {
  font-size: $size14;
  line-height: $size20;
  font-weight: 400;
  color: $c-gray700;
  width: 27.5rem;
  margin: auto;
  text-align: center;
}

.image {
  width: 45rem;
}
