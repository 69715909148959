.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  @media (max-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.8rem;
  }
}
