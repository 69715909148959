@import 'css/variables';

.react-select-with-icon-container {
  margin-bottom: $scale1;
  display: flex;
  flex-direction: column;
  gap: $size8;
}

.react-select-with-icon__control {
  border-radius: $radius !important;
  border: 1px solid #cbcbcb !important;
  cursor: pointer !important;

  img {
    margin-left: $size8;
    margin-right: -$size2;
    width: $size16;
    height: auto;
  }
}

.react-select-with-icon__placeholder {
  display: flex;
  gap: $size6;
  align-items: center;
}

.react-select-with-icon__control.react-select-with-icon__control--is-focused {
  box-shadow: 0px 2px 3px 0px rgba(16, 24, 32, 0.0509803922);
  border: 1px solid $c-primary !important;
}

.react-select-with-icon__menu {
  margin-top: $size8 !important;
  padding-top: 0 !important;
  z-index: 2 !important;
}

.react-select-with-icon__option {
  background-color: $c-white !important;
  cursor: pointer;
  color: $c-gray900 !important;
  display: inline-block;
  white-space: nowrap;
  img {
    display: inline-block;
    width: $size16;
    height: $size16;
    margin-right: $size8;
    margin-bottom: -$size3;
  }
}

.react-select-with-icon__option.react-select-with-icon__option--is-focused {
  background-color: none !important;
  cursor: pointer;
  color: $c-gray900 !important;
}

.react-select-with-icon__option.react-select-with-icon__option--is-selected {
  background-color: none !important;
  color: $c-gray900 !important;
}

.react-select-with-icon__option:hover {
  background-color: $c-white !important;
  color: $c-gray900 !important;
}

.react-select-with-icon__indicator-separator {
  display: none;
}

///////////////////////////// outline dark //////////////////
///
///

.react-select-with-icon-outline-dark__control.react-select-with-icon__control--is-focused {
  box-shadow: 0px 2px 3px 0px rgba(16, 24, 32, 0.0509803922);
  border: 1px solid $c-primary800 !important;
  outline: none !important;
}

.react-select-with-icon-outline-dark__control {
  border-radius: $radius !important;
  border: 1px solid $c-primary800 !important;
  outline: none !important;
  cursor: pointer !important;

  img {
    margin-left: $size8;
    margin-right: -$size2;
    width: $size16;
    height: auto;
  }
}

.react-select-with-icon-outline-dark__menu {
  margin-top: $size8 !important;
  padding-top: 0 !important;
  z-index: 2 !important;
}

.react-select-with-icon-outline-dark__option {
  background-color: $c-white !important;
  cursor: pointer;
  color: $c-gray900 !important;
  display: inline-block;
  white-space: nowrap;
  img {
    display: inline-block;
    width: $size16;
    height: $size16;
    margin-right: $size8;
    margin-bottom: -$size3;
  }
}

.react-select-with-icon-outline-dark__option.react-select-with-icon-outline-dark__option--is-focused {
  background-color: none !important;
  cursor: pointer;
  color: $c-gray900 !important;
}

.react-select-with-icon-outline-dark__option.react-select-with-icon-outline-dark__option--is-selected {
  background-color: none !important;
  color: $c-gray900 !important;
}

.react-select-with-icon-outline-dark__option:hover {
  background-color: $c-white !important;
  color: $c-gray900 !important;
}

.react-select-with-icon-outline-dark__placeholder {
  display: flex;
  gap: $size4;
}

.react-select-with-icon-outline-dark__indicator-separator {
  display: none;
}

.react-select-with-icon-outline-dark__indicator {
  color: $c-primary800 !important;
}

.react-select-with-icon-outline-dark__dropdown-indicator {
  color: $c-primary800 !important;
}

.react-select-with-icon-outline-dark__placeholder {
  display: flex;
  gap: $size6;
  align-items: center;
  color: $c-primary800 !important;
  text-transform: capitalize !important;
}

.react-select-with-icon__value-container {
  display: flex !important;
  align-items: center !important;
  gap: $size6 !important;
}

.react-select-with-icon__selected-icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// react-select-with-icon__single-value
