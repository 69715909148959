@import 'css/variables';

.auth {
  flex: 1;
  padding: 0 0 0 $sidebarWidth;
  min-height: 100vh;
  background-color: $c-bg-additional;

  @media (max-width: $mobile) {
    padding: $size25 0;
  }
}
