@import 'css/variables';

.react-select-multi-with-icon-container {
  margin-bottom: $scale1;
  display: flex;
  flex-direction: column;
  gap: $size8;
}

.react-select-multi-with-icon__control {
  border-radius: $radius !important;
  border: 1px solid $c-gray200 !important;
  cursor: pointer !important;

  img {
    margin-left: $size8;
    margin-right: -$size2;
    width: $size16;
    height: auto;
  }
}

.react-select-multi-with-icon__control.react-select-multi-with-icon__control--is-focused {
  box-shadow: 0px 2px 3px 0px rgba(16, 24, 32, 0.0509803922);
  border: 1px solid $c-green500 !important;
}

.react-select-multi-with-icon__menu {
  margin-top: $size8 !important;
  padding-top: 0 !important;
  z-index: 2 !important;
}

.react-select-multi-with-icon__option {
  background-color: transparent !important;
  cursor: pointer;
  color: $c-gray900 !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: $size8;

  input[type='checkbox'] {
    accent-color: $c-primary;
    background-color: $c-gray50 !important;
    border: 1px solid $c-gray100 !important;
    border: 0 !important;
    color: white !important;
  }

  img {
    width: $size16;
    height: $size16;
  }
}

.react-select-multi-with-icon__option.react-select-multi-with-icon__option--is-focused {
  background-color: none !important;
  cursor: pointer;
  color: $c-gray900 !important;
}

.react-select-multi-with-icon__option.react-select-multi-with-icon__option--is-selected {
  background-color: none !important;
  color: $c-gray900 !important;
}

.react-select-multi-with-icon__option:hover {
  background-color: $c-white !important;
  color: $c-gray900 !important;
}

.react-select-multi-with-icon__indicator-separator {
  display: none;
}
