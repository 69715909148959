.label {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--radius);
  border: 1px solid var(--color-gray75);
  padding: 4rem 1.2rem 0.8rem 1.2rem;
}

.base img {
  width: 2rem;
  padding: 0.2rem;
  background-color: var(--color-gray50);
  border-radius: 0.4rem;
  height: auto;
}
