@import 'css/variables';

.loading {
  opacity: 0.5;
}

.button {
  width: 48%;
  margin-top: $size16;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
}

.bottomButton {
  button {
    position: absolute;
    bottom: $size60;
    z-index: 6;
    width: auto;
  }
}

.small {
  input {
    height: $size40;
  }

  button {
    height: $size44;
    padding: 0 $size40;
  }
}
