@import 'css/variables';

.base {
  padding: $size12;
  background-color: $c-bg-additional;
  border-radius: $radius;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  min-width: $size220;
  text-align: left;

  @media (max-width: 480px) {
    padding: $size12 $size12 $size12 0;
  }

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: $size16;
    line-height: $size24;
    white-space: nowrap;
  }

  p {
    font-size: $size14;
    line-height: $size20;
  }
}
