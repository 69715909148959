.trend-label {
  border: 1px solid var(--color-gray500);
  border-radius: 0.8rem;
  display: inline-block;
}

.trendLabelValue {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.2rem;
  height: 1.2rem;
  margin: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: var(--color-gray700);
}

.trend-growth {
  background-color: var(--color-green50);
  border: 1px solid var(--color-green500);
}

.trend-decrease {
  background-color: var(--color-red50);
  border: 1px solid var(--color-red500);
}
