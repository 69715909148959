@import 'css/variables';

.giLegend {
  position: relative;
  width: 100%;
  min-width: $size450;
  height: $size150;
  border-radius: $size8;
  padding: $size16;
  border: 1px solid $c-white;

  @media (max-width: 1366px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  @media (max-width: 640px) {
    grid-column-end: 2;
  }

  @media (max-width: 576px) {
    min-width: auto;
    height: auto;
  }
}

.bgOpacity {
  background-color: $c-white;
  opacity: 0.4;
  position: relative;
  width: 100%;
  min-width: $size450;
  height: $size150;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  @media (max-width: 576px) {
    min-width: 100%;
    height: 100%;
  }
}

.titleContainer p {
  z-index: 1;
}

.titleContainer svg {
  z-index: 1;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;
}

.growthIndexLegendContent {
  display: flex;
  gap: $size4;
  margin: $size18 $size10 auto $size10;
  justify-content: center;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
