@import 'css/variables';

.react-select-container {
  margin-bottom: $scale1;
}

.react-select__control {
  border-radius: $radius !important;
  border: 1px solid #cbcbcb !important;
  cursor: pointer !important;
}

.react-select__control.react-select__control--is-focused {
  box-shadow: 0px 2px 3px 0px rgba(16, 24, 32, 0.0509803922);
  border: 1px solid $c-primary !important;
}

.react-select__menu {
  margin-top: $size8 !important;
  padding-top: 0 !important;
}

.react-select__option {
  background-color: $c-white !important;
  cursor: pointer;
  color: $c-gray900 !important;
}

.react-select__option.react-select__option--is-focused {
  background-color: none !important;
  cursor: pointer;
  color: $c-gray900 !important;
}

.react-select__option.react-select__option--is-selected {
  background-color: none !important;
  color: $c-gray900 !important;
}

.react-select__option:hover {
  background-color: $c-white !important;
  color: $c-gray900 !important;
}

.react-select__indicator-separator {
  display: none;
}
