@import 'css/variables';

.subnav {
  position: relative;
  padding: $scale1 $scale4 0;
}

.item {
  color: $c-text;
  display: inline-block;
  padding-bottom: $scale;
  margin-right: $scale2;
  text-decoration: none;

  &.active {
    border-bottom: 2px solid $c-primary;
  }
}
