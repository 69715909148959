@import 'css/variables';

.base {
  font-size: $size14;
  color: $c-gray500;
  padding-left: $size20;
  position: relative;
  white-space: nowrap;
}

.dark {
  color: $c-gray700;

  &:before {
    content: url('/assets/icons/eye-dark.png');
    position: absolute;
    top: 1px;
    left: 0;
  }
}

.light {
  color: $c-gray500;

  &:before {
    content: url('/assets/icons/eye-light.png');
    position: absolute;
    top: 1px;
    left: 0;
  }
}
