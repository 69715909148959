.info-tooltip-container {
  z-index: 2;
  padding: 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: var(--color-gray700);
  font-size: 1.4rem;
  line-height: 2rem;
}

.list-item {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.logo-size-m {
  width: 1.6rem;
  height: 1.6rem;
}

.logo-size-xl {
  width: 2.4rem;
  height: 2.4rem;
}
