.add-dapp-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.special-tooltip {
  position: absolute;
  border: 0.1rem solid var(--color-green500);
  background-color: var(--color-white);
  border-radius: 0.8rem;
  height: 4.8rem;
  width: 22.4rem;
  top: -0.6rem;
  right: 12.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  animation: shadow-flicker 1.5s infinite ease-in-out;
}

@keyframes shadow-flicker {
  0% {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  }
}
