.button-auth-nav {
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray200);
  border-radius: 0.6rem;
  padding: 1.2rem 1rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
}
