.section {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: flex-start;
  gap: 1.6rem;
}

@media (max-width: 1200px) {
  .section {
    flex-direction: column;
    gap: 1.2rem;
  }
}

.inputs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.6rem;
}
