.segment-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  width: 15rem;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
