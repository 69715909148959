.add-new-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 3.2rem;
}

.elements-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  gap: 2.4rem;
  cursor: pointer;
}

.element-list {
  text-align: center;
  width: 6.4rem;
}

.element-name {
  margin-top: 1.6rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.element-icon {
  margin: auto;
}

.link {
  text-decoration: none;
  color: #000;
}
