@import 'css/variables';

.filtersSection {
  margin-top: $size16;
  margin-bottom: $size16;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $size16;
}

.left-column {
  display: flex;
  justify-content: flex-start;
  gap: $size16;
  flex-wrap: wrap;
}

.right-column {
  display: flex;
  justify-content: flex-end;
  gap: $size16;
  flex-wrap: wrap;
}

@media (max-width: 420px) {
  .filtersSection {
    display: grid;
    grid-template-columns: 1fr;
  }

  .left-column,
  right-column {
    display: grid;
    grid-template-columns: 1fr;
  }
}
