.user-nav {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.user-card {
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray200);
  border-radius: 0.6rem;
  padding: 1.1rem 1.2rem;
  display: flex;
  gap: 1.2rem;
}

.user-information {
  text-align: left;
}

.button-user-option {
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray200);
  border-radius: 0.6rem;
  height: 4rem;
  padding-top: 1.2rem;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

@media (min-width: 1024px) {
  .button-user-option {
    width: 10.8rem;
  }
}

.user-settings {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}
