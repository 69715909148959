@import 'css/variables';

.base {
  width: 100%;
  height: 100%;
  padding: 0 $size32;
  margin: auto;
  position: relative;

  @media (max-width: 1332px) {
    // iframe changed margin
    padding: 0 $size48;
  }

  @media (max-width: $mobile) {
    //  iframe margin
    padding: 0 $size20;
  }

  @media (max-width: 48rem) {
    // 768 - iframe changed margin
    padding: 0 $size16;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.grid {
  display: grid;
}

.marginTop16 {
  margin-top: $size16;
}

.noPadding {
  padding: 0;
}

.marginBottom8 {
  margin-bottom: $size8;
}

.marginBottom24 {
  margin-bottom: $size24;
}
