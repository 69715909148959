@import 'css/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $size16;
}

.title {
  font-size: $size18;
  line-height: $size28;
  font-weight: 600;
  color: $c-gray900;
}
