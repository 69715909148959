.container {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
}

@media (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
