.section {
  border-radius: 1.2rem;
  margin: 1.6rem 0 2.4rem 0;
  padding: 2.4rem;
  position: relative;
}

.ecosystem-color {
  background-color: #e9f6ee;
}

.dapp-color {
  background-color: #e9eff6;
}

.section::before {
  content: url('features/gi/shared/assets/images/left-bg-donat.png');
  position: absolute;
  bottom: -0.3rem;
  left: 1rem;
  opacity: 0.3;
}
