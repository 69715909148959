.tooltip-base {
  z-index: 2;
  border-radius: var(--radius);
  background-color: var(--color-seconday500) !important;
  opacity: 1 !important;
  box-shadow: 0 !important;
}

.arrow {
  display: block;
}

.noArrow {
  display: none;
}

.light {
  border: 0.1rem solid var(--color-gray100) !important;
  background-color: var(--color-white) !important;
  padding: 1.2rem 1.6rem !important;
  box-shadow: 0px 8px 8px -4px #10182008;
  box-shadow: 0px 20px 24px -4px #10182014;
}

.small {
  max-width: 23rem;
}
