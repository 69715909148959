@import 'css/variables';

.sorterButton {
  border: 1px solid $c-green500;
  background-color: $c-green25;
  border-radius: $radius;
  padding: 0 $size12;
  height: $size36;
  display: flex;
  align-items: center;
  gap: $size6;
}

.sorterText {
  font-size: $size14;
  line-height: $size20;
  color: $c-gray900;
  font-weight: 500;
}
