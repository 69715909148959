.filtersSection {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.left-column {
  display: flex;
  justify-content: flex-start;
  gap: 1.6rem;
  flex-wrap: wrap;
}

.right-column {
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  flex-wrap: wrap;
}

@media (max-width: 420px) {
  .filtersSection {
    display: grid;
    grid-template-columns: 1fr;
  }

  .left-column,
  .right-column {
    display: grid;
    grid-template-columns: 1fr;
  }
}
