@import 'css/variables';

.base {
  position: relative;
  display: block;
  color: $c-red500;
  font-size: $size12;
  font-weight: 500;
  padding: 0 0 0 $size16;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
