@import 'css/variables';

.header {
  position: relative;
  text-align: center;
  margin-bottom: $scale1;
}

.title {
  color: $c-text;
  font-family: 'Inter', sans-serif;
  font-size: $size48 !important;
  font-weight: 600;
  line-height: $size54 !important;
  letter-spacing: -1px;
  margin-bottom: $scale-3;
}

.subtitle {
  color: $c-text;
  font-weight: 600;
  font-size: $scale1;
  margin-top: 0;
  margin-bottom: $scale-3;

  @media (min-width: $mobile) {
    font-size: $scale2;
  }
}

.brand,
.dark {
  color: white;
}
