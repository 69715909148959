.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 2.5rem;
  height: 1.4rem;
  padding: 0;
  border-radius: 2rem;
  background-color: var(--color-white);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-white);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--color-white);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-white);
}

.react-toggle-track-check {
  position: absolute;
  width: 1.4rem;
  height: 1rem;
  top: 0rem;
  bottom: 0rem;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 0.8rem;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0rem;
  bottom: 0rem;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 1rem;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--color-gray100);
  border-radius: 50%;
  background-color: var(--color-gray300);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 1.2rem;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
}

/* added style for primary color */
.react-toggle-primary .react-toggle-track {
  background-color: var(--color-primary50);
  border: 0.04rem solid var(--color-primary500);
}

/* added style for white color */
.react-toggle-white .react-toggle-track {
  background-color: var(--color-white);
  border: 0;
}
