@import 'css/variables';

.section {
  margin-top: $size12;
  margin-top: $size16;
  margin-bottom: $size24;
  display: flex;
  justify-content: space-between;
  gap: $size16;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  gap: $size16;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}
