@import 'css/variables';

.base {
  height: $size60;
  display: flex;
  align-items: center;

  @media (max-width: $mobile) {
    height: auto;
    display: block;
    padding-bottom: $size8;
  }
}

.underlyingLine {
  border-bottom: 1px solid $c-gray200;
}

.link {
  text-decoration: none;
  position: relative;
  font-size: $size16;
  line-height: $size24;
  color: $c-text;
  margin-right: $size24;

  @media (max-width: $mobile) {
    display: block;
  }
}

.activeLink {
  font-weight: 600;

  @media (max-width: $mobile) {
    color: $c-primary;
  }
}

.activeLink:after {
  @media (min-width: $mobile) {
    content: '';
    position: absolute;
    bottom: -$size18;
    left: 0;
    height: $size3;
    width: 100%;
    background: $c-green500;
  }
}
