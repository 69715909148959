.slide {
  min-width: 34.6rem;
  height: 25rem;
  text-align: center;

  @media (max-width: 480px) {
    min-width: 100%;
  }
}

a {
  text-decoration: none;
}

.image {
  margin: auto;
  max-height: 14.4rem;
}

.name {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  color: var(--color-gray900);
  text-decoration: none;
  text-align: left;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 1rem;
}

.dapp-info-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.4rem;
  align-items: center;
  margin-top: 0.4rem;
}

.dapp-info-container img {
  height: 2rem;
  width: 2rem;
}
