@import 'css/variables';

.inviteEmailsContainer {
  margin-top: $size16;
  display: grid;
  grid-template-columns: auto $size100;
  grid-template-rows: auto;
  gap: $size8;

  @media (max-width: 480px) {
    grid-template-columns: auto;
    margin-bottom: $size24;
  }

  input {
    border-color: $c-gray200;
  }

  > button {
    margin-top: $size26;

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }
}
