@import 'css/variables';

.widgetContainer {
  position: fixed;
  width: 80%;
  max-width: $size720;
  top: $size80;
  right: 0;
  bottom: 0;
  padding: $size24;
  background-color: $c-white;
  border: 1px solid $c-gray75;
  border-radius: $size8;
  overflow: auto;
  z-index: 6;
  box-shadow: 0px 8px 8px -4px #10182008;
  box-shadow: 0px 20px 24px -4px #10182014;

  @media (max-width: $mobile) {
    width: auto;
    max-width: 100%;
    margin: $size16;
    left: 0;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size6;
  margin: $size12 0 $size16 0;
  font-size: $size16;
  line-height: $size24;
  font-weight: 400;
  color: $c-gray800;
}

.gridOfBasicMetrics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: $size16;
  row-gap: $size16;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $size12;
    row-gap: $size12;
  }
}

.metricContainer {
  border: 1px solid $c-gray75;
  border-radius: $radius;
  padding: $size8 $size16;
  width: 100%;
}

.metricName {
  color: $c-gray600;
  font-size: $size12;
  line-height: $size18;
  white-space: nowrap;
}

.metricValue {
  color: $c-gray800;
  font-size: $size14;
  line-height: $size20;
}

.timelineFrame {
  border: 1px solid $c-gray75;
  border-radius: $radius;
}

.dappPosition {
  display: flex;
  align-items: center;
  gap: $size8;
  color: $c-gray700;
  font-size: $size14;
  line-height: $size20;
  margin-top: $size3;
  margin-bottom: $size3;
}

.dappImage {
  width: $size24;
  height: $size24;
}

.gridOfDapps {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: $size16;
  min-height: $size24;

  @media (max-width: $mobile) {
    gap: $size12;
  }
}

.interactionDate {
  margin-top: $size4 !important;
  padding: 0 !important;
  font-size: 1.4rem !important;
}

.interaction {
  margin: $size16 0 $size32 0;
}
