@import 'css/variables';

.no-dapp-add-dapp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: $size24;
  align-items: center;
}

.no-dapp-grid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid $c-gray100;
  border-radius: $radius;
  padding: $size24;
  gap: $size12;
  align-items: center;

  @media (max-width: 640px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    border: none;
  }
}

.text {
  color: $c-gray900;
  font-size: $size16;
  line-height: $size24;
  font-weight: 600;
}

.highlighted-text {
  margin-top: $size4;
  font-weight: 400;
  display: block;
  color: $c-gray600;
}
