.form-container {
  /* max-height: 57.8rem; */
  margin-bottom: 1.2rem;

  @media (max-width: 1024px) {
    max-height: 64rem;
    overflow-y: auto;
  }

  @media (max-width: 640px) {
    max-height: 48rem;
  }
}

.form-container::-webkit-scrollbar {
  width: 1.6rem;
  border-left: 0.8rem var(--color-white) solid;
}

.form-container::-webkit-scrollbar-track {
  background: var(--color-gray50);
  width: 0.8rem;
  border-left: 0.8rem var(--color-white) solid;
}

.form-container::-webkit-scrollbar-thumb {
  background: var(--color-gray300);
  width: 0.8rem;
  border-left: 0.8rem var(--color-white) solid;
}

.form-container::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray500);
}

.form-container::-webkit-scrollbar-thumb:active {
  background: var(--color-gray500);
}
