@import 'css/variables';

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.blockchainTable {
  border-collapse: collapse;
  width: 100%;
}

@media (min-width: 1024px) {
  .blockchainTable td:nth-child(1) {
    width: 15%;
    min-width: $size120;
  }

  .blockchainTable td:nth-child(2) {
    width: 30%;
    min-width: 25rem;
  }

  .blockchainTable td:nth-child(3) {
    width: 15%;
    min-width: 14rem;
  }

  .blockchainTable td:nth-child(4) {
    max-width: 40%;
  }
}

.head {
  font-size: $size12;
  line-height: $size18;
  color: $c-primary300;
  font-weight: 500;
  text-align: left;

  th {
    padding: $size12 $size24;

    @media (max-width: 640px) {
      padding-left: $size16;
      padding-right: $size16;
    }
  }
}

.row {
  height: $size72;
  text-align: left;
  border-top: 1px solid #eaecf0;
  font-size: $size16;
  line-height: $size24;
  font-weight: 400;
  color: $c-gray800;

  td a {
    color: $c-primary500;
  }
}

.row:hover {
  background-color: #faf9f9;
}

.blockchain-column-container {
  padding: 0 2.4rem;
  display: flex;
  align-items: center;
  height: 7.2rem;

  @media (max-width: 640px) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.tdContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size12;
  white-space: nowrap;
}

.growthIndexColumnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;
  min-width: $size96;
}

.changeRankContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size4;
}

.progressRank {
  color: $c-green500;
}

.regressRank {
  color: $c-red500;
}

.growthIndexTimelineColumnContainer {
  @media (max-width: $mobile) {
    min-width: $size150;
  }
}

.logo {
  max-height: $size24;
  max-width: $size24;
}
