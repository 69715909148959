.slide-button {
  height: 25rem;
  min-width: 34.6rem;
  border-radius: var(--radius);
  background-color: var(--color-green50);
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  align-items: center;
  border: 0.2rem solid var(--color-green500);

  @media (max-width: 480px) {
    min-width: 100%;
  }
}
