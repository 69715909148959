@import 'css/variables';

.container {
  width: $size345;

  @media (max-width: 640px) {
    width: 100%;
  }

  > button {
    margin-top: $size16;
  }
}

.frame {
  border: 1px solid $c-gray200;
  margin-top: -$size16;
  margin-bottom: $size16;
  padding: $size12;
  border-radius: $radius;
  box-shadow: 0px 2px 3px 0px #1018200d;
  text-align: center;

  p {
    font-size: $size16;
    line-height: $size24;
    font-weight: 500;
  }

  svg {
    margin: auto;
    margin-bottom: $size8;
  }
}

.informationText {
  text-align: center;
  font-size: $size14;
  line-height: $size20;
  color: $c-gray700;
  margin-bottom: $size8;
}
