@import 'css/variables';

.checklist {
  margin-left: 0;
  list-style: none;
}

.item {
  position: relative;
  text-align: left;
  background-size: $size24;
  padding-left: $size32;
  margin-bottom: $size12;
  font-size: $size16;
  line-height: $size24;
  color: $c-gray700;

  span {
    font-weight: 500;
    color: $c-primary800;
  }
}

.checkGreen {
  background-image: url('./icons/check-correct.svg');
}

.checkEmpty {
  background-image: url('./icons/check-empty.svg');
}

// .checkWhite {
//   background-image: url('/assets/icons/ico-check-white.svg');
// }

.cross {
  background-image: url('./icons/ico-cross-red.svg');
}

.interactive {
  cursor: pointer;
}
