.grid-item {
  padding: 2.4rem;
  background-color: var(--color-white);
  border-radius: 0.8rem;
  border: 1px solid #ececec;
  width: 100%;
  height: 100%;
}

.item-title {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-gray900);
  margin-bottom: 2.4rem;
}

.move-wrapper {
  height: 100%;
  cursor: grab;
}
