@import 'css/variables';

.section {
  margin-top: $size16;
  margin-bottom: $size24;
  display: flex;
  justify-content: flex-start;
  gap: $size16;
  margin-top: $size12;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: $size12;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  gap: $size16;
  margin-top: $size12;
}

.buttonPosition {
  position: absolute;
  right: $size8;
  bottom: $size30;
  z-index: 3;
}

.toggle-frame {
  display: flex;
  align-items: center;
  gap: $size8;
  padding: $size6 $size12;
  border: 1px solid $c-gray100;
  border-radius: 0.8rem;
  height: $size36;
}

.toggle-frame-active {
  border: 1px solid $c-green500;
  background-color: $c-green25;
}
