.line {
  border-top: 1px solid var(--color-gray75);
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.or {
  position: relative;
  display: inline-block;
  z-index: 3;
  color: var(--color-gray400);
  font-size: 1.4rem;
  width: 100%;
  text-align: center;
  margin-bottom: 1.6rem;
}

.or:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0.1rem;
  width: 100%;
  z-index: -2;
  margin-top: -0.1rem;
  background-color: var(--color-gray100);
}

.or:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3.9rem;
  height: 100%;
  z-index: -1;
  transform: translate(-50%, -50%);
  background-color: var(--color-white);
}

.gray200 {
  border-top: 1px solid var(--color-gray200);
}

.gray100 {
  border-top: 1px solid var(--color-gray100);
}
