.dashboard-layout {
  padding: 0 0 0 var(--sidebar-width);
  min-height: 100vh;
  background-color: var(--color-white);
  display: grid;
}

@media (max-width: 1024px) {
  .dashboard-layout {
    padding: 0;
    min-height: calc(100vh - 8rem);
  }
}
