@import 'css/variables';

.base {
  position: relative;
  width: auto;
  height: $headerHeight;
  z-index: 1;
  background: white;
  padding: $size25 0 $size25 $sidebarWidth;
  font-weight: 600;

  @media (max-width: $mobile) {
    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale $scale $scale4;
    border: none;
  }

  @media (min-width: $mobile) {
    h1 {
      font-size: $scale1;
    }
  }
}

.borderBottom {
  border-bottom: 1px solid $c-gray100;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $size30;
}
