.ranking-banner-container {
  background-size: cover;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
  min-width: 15rem;
  border: 1px solid var(--color-white);
}

@media (max-width: 1366px) {
  .ecosystemBannerContainer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

@media (max-width: 480px) {
  .ecosystemBannerContainer {
    min-width: auto;
  }

  .ecosystemBannerContainer img {
    padding: 1.6rem;
    width: 100%;
  }
}

.opacity-cover {
  cursor: pointer;
  opacity: 0.4;
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}

.ecosystemBannerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}
