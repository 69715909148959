@import 'css/variables';
@import 'css/normalize';

// html {
//   font-size: 62.5% !important;
//   1rem = 10px
// }

:root {
  font-family: 'Roboto', sans-serif;

  --breakpoint-mobile: 102.4rem;
  --main-bg-color: #ffffff;
  --radius: 0.6rem;

  --icon-fill: #ffffff;
  --icon-stroke: #ffffff;

  --color-primary500: #48bd98;
  --color-primary50: #edf8f5;

  --color-secondary900: #041c27;
  --color-secondary800: #062434;
  --color-secondary600: #093c56;

  --color-text: #222222;

  --color-gray900: #3c3c3c;
  --color-gray800: #4e4e4e;
  --color-gray700: #656565;
  --color-gray500: #8e8e8e;
  --color-gray200: #cbcbcb;
  --color-gray100: #dcdcdc;
  --color-gray75: #ececec;
  --color-gray50: #f4f4f4;
  --color-white: #ffffff;

  --color-danger: red;
}

body {
  min-height: 30rem;
  margin: 0;
  padding: 0;
  /* background-color: var(--main-bg-color); */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  line-height: 0;
}

.full-width {
  max-width: 100%;
  width: 100%;

  > * {
    width: 100%;
    max-width: 100%;
  }
}

.restrict-width {
  max-width: $maxWidth;
  margin: 0 auto;
}

.align-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.align-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.align-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.align-center {
  text-align: center;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 40%;
}

.relative {
  position: relative;
}

.min-height {
  min-height: $minHeight;
}

.pl24 {
  padding-left: $size24;
}

.pr24 {
  padding-right: $size24;
}

.pb20 {
  padding-bottom: $size20;
}

.pt20 {
  padding-top: 20px;
}

.mt48 {
  margin-top: $size48;
}

.mt24 {
  margin-top: $size24;
}

.mt16 {
  margin-top: $size16;
}

.mt8 {
  margin-top: $size8;
}

.mb24 {
  margin-bottom: $size24;
}

.mb20 {
  margin-bottom: $size20;
}

.mb16 {
  margin-bottom: $size16;
}

.mb8 {
  margin-bottom: $size8;
}
