.add-dapp {
  padding: 2.4rem 3.2rem 0 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  min-height: 30rem;
}

.segment-container {
  width: 37rem;
}

.segment-top-bar {
  display: grid;
  grid-template-columns: 15rem 15.6rem;
  gap: 1.2rem;
  margin-bottom: 1.6rem;
}

.segment-top-bar input {
  max-width: 12.6rem;
}

.segment-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
}

.segment-selects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  margin-bottom: 2.4rem;
}

.airdrop-container {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.airdrop-value-container {
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
}

.segment-selects-container {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.loader-container {
  display: relative;
  min-height: 37.4rem;
  width: 100%;
}
