@import 'css/variables';

.sankeySection {
  width: 100%;
  position: relative;
  border: 1px solid $c-gray75;
  border-radius: $size8;
  margin-bottom: $size24;
  padding: 0 $size24 $size24 $size24;
}

.barContainer {
  padding: $size24 0 $size28 0;
  display: flex;
  justify-content: flex-start;
  gap: $size8;
  align-items: center;

  @media (max-width: $mobile) {
    padding: $size20 0 $size12 0;
  }
}
