.card {
  position: relative;
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  background: var(--color-white);
  border-radius: var(--radius);
  border: 0.1rem solid var(--color-gray200);
  height: 100%;
}

/* .card {
  font-weight: 700;
  margin: 2.4rem 0 0;
} */

.card:last-of-type {
  margin-bottom: 0;
}

.header {
  position: relative;
  overflow: hidden;
  text-align: left;
  color: var(--color-primary500);
  padding-bottom: 2rem;
  margin-bottom: 3.1rem;
  background-color: var(--color-white);
}

.title {
  float: left;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 1px solid var(--color-primary500);
  padding-bottom: 1rem;
}

.full {
  height: 100%;
  width: 100%;
}
