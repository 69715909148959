@import 'css/variables';

.single-value {
  padding: $size16 $size12;
  background: $c-gray25;
  border-radius: $radius;
  border: 1px solid $c-gray200;
  text-align: center;
  position: relative;
  min-width: $size150;
  min-height: $size150;
  position: relative;
}

.name-container {
  display: flex;
  align-items: center;
  gap: $size4;
  margin-top: 7.6rem;
  justify-content: center;
}

.name {
  font-size: $size14;
  line-height: $size20;
  color: $c-gray700;
  white-space: nowrap;
}

.value {
  font-size: $size24;
  line-height: $size32;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  transition: transform 1s ease-in-out;
  width: 100%;
  word-wrap: break-word;
  padding: 0 $size6;
}

.area-cover {
  min-height: $size22;
}
