@import 'css/variables';

.app {
  flex: 1;
  padding: $size24 0 $size24 $sidebarWidth;
  min-height: calc(100vh - $footerHeight);
  background-color: $c-gray50;

  @media (max-width: $mobile) {
    padding: $size24 0;
  }
}
