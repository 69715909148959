@import 'css/variables';

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: black;
  background: rgba(0, 0, 0, 0.7);

  form {
    content: '';
    display: table;
    clear: both;
    width: 100%;
  }

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    text-align: left;
    transform: translate(-50%, -50%);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    min-width: $size345;

    @media (max-width: 640px) {
      width: 100%;
      padding-left: $size25;
      padding-right: $size25;
    }
  }
}

.text {
  max-width: $size345;
}

.modal-appear {
  opacity: 0;
}

.modal-appear-done {
  opacity: 1;

  .modal {
    opacity: 1;
    top: 50%;
  }
}
