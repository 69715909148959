@import 'css/variables';

.input {
  margin-bottom: $scale1;
}

.base {
  position: relative;
  cursor: pointer;
  color: $c-text;
  width: 100%;
  overflow: hidden;
  border: 1px solid $c-border-dark;
  background-color: white;
  border-radius: $radius;
}

.select {
  position: relative;
  width: 100%;
  color: $c-text;
  font-size: $scale;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: transparent;
  outline: none;
  cursor: pointer;
  padding: $scale;
}

.select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.select::-ms-expand {
  display: none;
}

.success {
  border-color: lighten($c-green, 3%);
}

.errorWrapper {
  border: none;

  &:before {
    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-red;
  }

  &:after {
    margin-top: -$scale;
  }
}

.error {
  color: $c-red;
  z-index: 2;
}

.warningWrapper {
  &:before {
    // psuedo border
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: $scale2;
    border-radius: $radius;
    border: 1px solid $c-orange;
  }
}

.warning {
  color: $c-orange;
  z-index: 2;
}

.message {
  margin-bottom: 0;
}

.small {
  padding: $size5 $size15;
  height: $size40;
}

.important {
  background-color: $c-primary;
  color: #ffffff;
  cursor: pointer;

  &:after {
    content: url('./icons/ico-dropdown-white.svg');
    position: absolute;
    top: 50%;
    right: $size15;
    pointer-events: none;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
}

.standard {
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: $size15;
    width: 0.85rem;
    height: 0.5rem;
    opacity: 0.35;
    pointer-events: none;
    transform: translateY(-50%);
    background-size: contain;
    background-image: url('./icons/ico-dropdown.svg');
  }
}

.dark {
  padding-left: $size40;
  color: $c-primary800;
  font-size: $size14;
  line-height: $size20;
  font-weight: 600;
}

.darkOutline {
  border: 1px solid $c-primary800;
  color: $c-primary800;
  cursor: pointer;
  width: 35%;

  @media (max-width: 640px) {
    width: 50%;
  }

  &:before {
    content: url('/assets/icons/eye-dark600.svg');
    position: absolute;
    top: 50%;
    opacity: 1;
    left: $size15;
    pointer-events: none;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: $size15;
    width: 0.85rem;
    height: 0.5rem;
    opacity: 1;
    pointer-events: none;
    transform: translateY(-50%);
    background-size: contain;
    background-image: url('./icons/ico-dropdown-dark.svg');
  }
}
