.settings-chart-container {
  height: 3.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  padding: 0 3.2rem;
}

.charts-icons-container {
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
}

.chart-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
}

.chart-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
