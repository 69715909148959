@import 'css/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: $size5;
  width: $size230;
  z-index: 5;
}

.title {
  color: $c-gray800;
  font-size: $size14;
  line-height: $size20;
  font-weight: 600;
}

.paragraph {
  color: $c-gray700;
  font-size: $size14;
  line-height: $size20;
  font-weight: 400;
}

.list {
  color: $c-gray700;
  font-size: $size14;
  line-height: $size20;
  padding-left: $size20;

  li {
    list-style-type: disc;
  }
}
