@import 'css/variables';

.box {
  padding: $size4 $size8;
  background-color: $c-off-white;
  border: 1px solid $c-gray100;
  border-radius: $radius;
  text-align: center;
  height: $size28;
  font-size: $size14;
  line-height: $size20;
}

.pointer {
  cursor: pointer;
}

.userName {
  color: $c-primary800;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.userEmailNoMargin {
  color: $c-gray700;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.userWrapper {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.buttonContainer {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable {
  max-height: 250px; // You might want to override this in JavaScript if you still need dynamic height
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar {
    width: thin;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;

    &:hover {
      background-color: #555;
    }
  }
}

.users-with-access-container {
  display: grid;
  grid-template-columns: auto $size70;
  align-items: center;
  justify-items: center;

  @media (max-width: 420px) {
    grid-template-columns: auto;
    justify-items: start;
    margin-bottom: $size12;
  }
}
