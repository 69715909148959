@import 'css/variables';

.base {
  margin: 0;

  h1,
  h2 {
    margin: 0;
    padding: 0;
  }

  .title {
    color: $c-primary800;
    font-size: $size20;
    line-height: $size28;
    font-size: $size18;
    font-weight: 600;
  }

  .subtitle {
    margin: $size4 0 0 0;
    color: $c-gray700;
    font-size: $size16;
    line-height: $size24;
    font-weight: 400;
  }
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;
}

.logo {
  max-height: $size24;
  max-width: $size24;
}

.margin {
  margin: $size24 0 $size32 0;
}

.fullWidth {
  width: 100%;
}
