.empty-metric-card {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray300);
  border-radius: var(--radius);
  padding: 0.8rem 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
}
