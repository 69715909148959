@import 'css/variables';

.widget {
  position: fixed;
  right: $size60;
  bottom: $size48;
  background-color: $c-white;
  box-shadow: 0 $size8 $size8 (-$size4) #10182008;
  box-shadow: 0 $size20 $size24 (-$size4) #10182014;
  width: $size390;
  padding: $size24;
  border-radius: $radius;
  border: 1px solid #ececec;
  z-index: 7;

  @media (max-width: 640px) {
    position: fixed;
    width: calc(100% - $size48);
    margin-left: $size24;
    margin-right: $size24;
    left: 0;
    right: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.checklist {
  margin-top: $scale1;
}

.text {
  color: $c-gray700;
  font-size: $size16;
  line-height: $size24;
  font-weight: 400;
  margin-bottom: $size8;
}
