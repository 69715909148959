@import 'css/variables';

.topUsers {
  width: 100%;
  position: relative;
  border-radius: $size8;
  margin-bottom: $size48;
  padding: 0 $size24;
  border: 1px solid $c-gray200;
  background-color: $c-green25;

  @media (max-width: $mobile) {
    padding: 0;
  }
}

.table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.barContainer {
  padding: $size20 0 $size12 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile) {
    padding: $size20 $size24 $size12 $size24;
  }

  @media (max-width: 640px) {
    padding: $size20 $size24 $size12 $size24;
    flex-direction: column;
    gap: 1.6rem;
  }
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  gap: $size8;
  align-items: center;
}

.usersTable {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  padding: 0 $size24;
}

.head {
  font-size: $size12;
  line-height: $size18;
  color: $c-gray600;
  font-weight: 500;
  height: $size72;

  th {
    padding: $size12 $size24;
    white-space: nowrap;

    @media (max-width: 640px) {
      padding-left: $size16;
      padding-right: $size16;
    }
  }
}

.row {
  height: $size72;
  border-top: 1px solid #eaecf0;
  font-size: $size16;
  line-height: $size24;
  color: $c-gray800;
  cursor: pointer;

  td {
    padding: 0 $size24;
    white-space: nowrap;

    @media (max-width: 640px) {
      padding-left: $size16;
      padding-right: $size16;
    }
  }
}

.listOfAdresses {
  list-style-type: none;
  display: flex;
  gap: $size12;
}

.addressText {
  color: $c-gray800;
  display: flex;
  align-items: baseline;
  gap: $size4;
}

.nameLabel {
  font-size: $size14;
  line-height: $size20;
  color: $c-gray500;
  font-weight: 400;
}
