@import 'css/variables';

.grid {
  display: grid;
  grid-template-columns: $size345 $size487;
  grid-template-rows: auto;

  @media (max-width: $mobile) {
    grid-template-columns: 100%;
    min-width: 480px;
  }

  @media (max-width: 640px) {
    grid-template-columns: 100%;
    min-width: auto;
  }
}
