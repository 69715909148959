.add-dapp {
  padding: 2.4rem 3.2rem 0 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-height: 30rem;

  @media (max-width: 1024px) {
    padding: 2.4rem 1.6rem 0 1.6rem;
  }
}

.table-mobile-container {
  @media (max-width: 1024px) {
    width: calc(100vw - 3.2rem);
    overflow-x: auto;
  }
}
