@import 'css/variables';

.text {
  margin-top: $size24;
  margin-bottom: $size8;
  font-size: $size14;
  line-height: $size20;
  color: $c-gray900;

  a {
    color: $c-primary;
    text-decoration: underline;
  }
}
