.input {
  position: relative;
  margin: 0;
  padding: 0 0.8rem;
  border-radius: 0.6rem;
  height: 3.2rem;
  max-width: 100%;
  border: 1px solid var(--color-gray200);
  -webkit-appearance: none;
  -webkit-border-radius: 0.6rem;
}

.input:focus {
  outline: none;
  border: 1.2px solid var(--color-green500);
  box-shadow: 0px 2px 3px 0px #1018200d;
}

.number-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.input-prefix-container {
  position: relative;
}

.xxsmall {
  height: 2.4rem;
}

.small {
  height: 3.2rem;
}

.medium {
  height: 3.6rem;
}

.large {
  height: 4rem;
}

.xlarge {
  height: 4.4rem;
}

.prefix {
  position: absolute;
  top: 50%;
  left: 0.8rem;
  transform: translate(0, -50%);
  z-index: 1;
}

.prefix-padding {
  padding-left: 2rem;
}
