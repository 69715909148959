/* modular scale em */
$scale-4: 0.4096rem;
$scale-3: 0.512rem;
$scale-2: 0.64rem;
$scale-1: 0.8rem;
$scale: 1rem; // 16px
$scale1: 1.25rem; // 20px
$scale2: 1.5625rem;
$scale3: 1.953125rem;
$scale4: 2.44140625rem;
$scale5: 3.0517578125rem;
$scale6: 3.8146972656rem;
$scale7: 4.768371582rem;
$scale8: 5.9604644775rem;
$scale9: 7.4505805969rem;
$scale10: 9.3132257462rem;
$scale11: 11.563rem; // 185px
$scale12: 14.5519152284rem;
$scale13: 18.1898940355rem;
$scale14: 22.7373675443rem;
$scale15: 28.4217094304rem;
$scale16: 35.527136788rem;
$scale17: 44.408920985rem;
$scale18: 55.5111512313rem;

$size2: 0.2rem; // 2px
$size3: 0.3rem; // 3px
$size4: 0.4rem; // 4px
$size5: 0.5rem; // 5px
$size6: 0.6rem; // 6px
$size8: 0.8rem; // 8px
$size10: 1rem; // 10px
$size11: 1.1rem; // 11px
$size12: 1.2rem; // 12px
$size14: 1.4rem; // 14px
$size15: 1.5rem; // 15px
$size16: 1.6rem; // 16px
$size18: 1.8rem; // 18px
$size20: 2rem; // 20px
$size22: 2.2rem;
$size24: 2.4rem; // 24px

$size25: 2.5rem; // 25px
$size26: 2.6rem; // 26px
$size28: 2.8rem; // 28px
$size30: 3rem; // 30px
$size32: 3.2rem; // 32px
$size34: 3.4rem; // 34px
$size36: 3.6rem; // 36px
$size38: 3.8rem; // 38px
$size40: 4rem; // 40px
$size42: 4.2rem; // 42px
$size44: 4.4rem; // 44px
$size48: 4.8rem; // 48px
$size50: 5rem; // 50px
$size54: 5.4rem; //54px
$size56: 5.6rem; // 56px
$size58: 5.8rem; //58px
$size60: 6rem; //60px
$size64: 6.4rem; //64px
$size65: 6.5rem; // 65px
$size70: 7rem; // 70px
$size72: 7.2rem; // 72px
$size76: 7.6rem; // 76px
$size80: 8rem; // 80px
$size90: 9rem; // 90px
$size96: 9.6rem; // 96px
$size100: 10rem; // 100px
$size110: 11rem; // 110px
$size113: 11.3rem;
$size116: 11.6rem; // 116px
$size120: 12rem; // 120px
$size128: 12.8rem; // 128px
$size150: 15rem; // 150px
$size160: 16rem; // 160px
$size164: 16.4rem; // 164px
$size175: 17.5rem; // 175px
$size190: 19rem; // 190px
$size200: 20rem; // 200px
$size210: 21rem; // 210px
$size220: 22rem; // 220px
$size228: 22.8rem; // 228px
$size230: 23rem; // 230px
$size240: 24rem; // 240px
$size276: 27.6rem; // 276px
$size280: 28rem; // 280px
$size285: 28.5rem; // 285px
$size300: 30rem;
$size320: 32rem;
$size324: 32.4rem;
$size325: 32.5rem;
$size345: 35.5rem;
$size378: 37.8rem; // 378px
$size390: 39rem;
$size400: 40rem;
$size393: 39.3rem;
$size450: 45rem; //450px
$size460: 46rem; //460px
$size487: 48.7rem;
$size500: 50rem;
$size610: 61rem; // 610px
$size640: 64rem; // 640px
$size670: 67rem; // 670px
$size720: 72rem;
// $size2: 0.125rem; // 2px
// $size3: 0.188rem; // 3px
// $size4: 0.25rem; // 4px
// $size5: 0.313rem; // 5px
// $size6: 0.375rem; // 6px
// $size8: 0.5rem; // 8px
// $size10: 0.625rem; // 10px
// $size11: 0.688rem; // 11px
// $size12: 0.75rem; // 12px
// $size14: 0.875rem; // 14px
// $size15: 0.938rem; // 15px
// $size16: 1rem; // 16px
// $size18: 1.125rem; // 18px
// $size20: 1.25rem; // 20px
// $size22: 1.375rem;
// $size24: 1.5rem; // 24px
// $size25: 1.5625rem; // 25px
// $size26: 1.625rem; // 26px
// $size28: 1.75rem; // 28px
// $size30: 1.875rem; // 30px
// $size32: 2rem; // 32px
// $size34: 2.125rem; // 34px
// $size36: 2.25rem; // 36px
// $size38: 2.375rem; // 38px
// $size40: 2.5rem; // 40px
// $size42: 2.625rem; // 42px
// $size44: 2.75rem; // 44px
// $size48: 3rem; // 48px
// $size50: 3.125rem; // 50px
// $size54: 3.375rem; //54px
// $size56: 3.5rem; // 56px
// $size58: 3.625rem; //58px
// $size60: 3.75rem; //60px
// $size64: 4rem; //64px
// $size65: 4.063rem; // 65px
// $size70: 4.375rem; // 70px
// $size72: 4.5rem; // 72px
// $size76: 4.75rem; // 76px
// $size80: 5rem; // 80px
// $size90: 5.625rem; // 90px
// $size96: 6rem; // 96px
// $size100: 6.25rem; // 100px
// $size110: 6.875rem; // 110px
// $size113: 7.063rem;
// $size116: 7.25rem; // 116px
// $size120: 7.5rem; // 120px
// $size128: 8rem; // 128px
// $size150: 9.375rem; // 150px
// $size160: 10rem; // 160px
// $size164: 10.25rem; // 164px
// $size175: 10.938rem; // 175px
// $size190: 11.875rem; // 190px
// $size200: 12.5rem; // 200px
// $size210: 13.125rem; // 210px
// $size220: 13.75rem; // 220px
// $size228: 14.25rem; // 228px
// $size230: 14.375rem; // 230px
// $size240: 15rem; // 240px
// $size276: 17.25rem; // 276px
// $size280: 17.5rem; // 280px
// $size285: 17.813rem; // 285px
// $size300: 18.75rem;
// $size320: 20rem;
// $size324: 20.25rem;
// $size325: 20.313rem;
// $size345: 21.563rem;
// $size378: 23.625rem; // 378px
// $size390: 24.375rem;
// $size400: 25rem;
// $size393: 24.563rem;
// $size450: 28.125rem; //450px
// $size460: 28.75rem; //460px
// $size487: 30.438rem;
// $size500: 31.25rem;
// $size610: 38.125rem; // 610px
// $size640: 40rem; // 640px
// $size670: 41.875rem; // 670px
// $size720: 45rem;

$mainContainer: 1440px;
$mainContentMargin: $size32;
$radius: 0.8rem;
$mobile: 1024px;
$tablet: 1024px;
$desktop: 1480px;
$fullHD: 1920px;
$maxWidth: 1000px;
$footerHeight: 134px;
$headerHeight: 88px;
$minHeight: 300px;
// $sidebarWidth: 14.25rem; // 228px
$sidebarWidth: 22.8rem; // 228px
