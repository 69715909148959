.react-calendar {
  width: 35rem;
  max-width: 100%;
  background: white;
  border: 0.1rem solid #dcdcdc;
  border-radius: 0.6rem !important;
  z-index: 3 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.8rem;
  margin-top: 8px;
  padding: 2.4rem;
  box-shadow: 0rem 0.8rem 0.8rem -0.4rem #10182008;
  box-shadow: 0rem 2rem 2.4rem -0.4rem #10182014;
  z-index: 2 !important;
}

.react-calendar--doubleView {
  width: 70rem;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.8rem;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.8rem;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 4.4rem;
}

.react-calendar__navigation button {
  min-width: 4.4rem;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.8rem;
  text-decoration: none;
  font-size: 1.2rem;
  color: #b3b3b3 !important;
  font-weight: 400 !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 1.2rem;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #4a5660;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 3.2rem 0.8rem;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 1rem 0.66rem;
  background: none;
  text-align: center;
  line-height: 1rem;
  font: inherit;
  font-size: 1.4rem;
  font-weight: 400 !important;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #edf8f5 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #edf8f5;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #edf8f5 !important;
}

.react-calendar__tile--active {
  background: #edf8f5;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #edf8f5;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__tile--range {
  background-color: #c6ebdf !important;
  color: #4a5660;
}

.react-calendar__tile--rangeStart {
  background-color: var(--color-primary) !important;
  color: white;
}

.react-calendar__tile--rangeEnd {
  background-color: var(--color-primary) !important;
  color: white;
}
