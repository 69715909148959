@import 'css/variables';

button.base,
a.base {
  color: $c-white;

  &:hover {
    color: $c-white !important;
  }
}

button.google,
a.google {
  background-color: $c-white;
  border: 1px solid $c-primary600;
  color: $c-primary600;

  &:hover {
    background-color: darken($c-primary600, 7%);
    transition: all 0.4s ease-in-out;

    svg {
      color: $c-white;
      transition: all 0.4s ease-in-out;
    }
  }
}

button.mail,
a.mail {
  background-color: $c-green;

  &:hover {
    background-color: darken($c-green, 7%);
  }
}

.signinButtons {
  text-align: center;
}

.signinButton {
  margin-bottom: $size16;
}

.socialMediaButtons {
  position: relative;
  float: left;
  margin-right: $size16;

  &:last-child {
    margin-right: 0;
  }
}

.or {
  position: relative;
  display: inline-block;
  z-index: 3;
  color: $c-gray400;
  font-size: $size14;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    z-index: -2;
    margin-top: -1px;
    background-color: $c-gray100;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $scale4;
    height: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: white;
  }
}
