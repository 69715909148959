@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

// shake animation
@-webkit-keyframes shake {
  from {
    transform: translateX(-5px);
    -ms-transform: translateX(-5px);
  }
  to {
    transform: translateX(5px);
    -ms-transform: translateX(5px);
  }
}

@-moz-keyframes shake {
  from {
    transform: translateX(-5px);
    -ms-transform: translateX(-5px);
  }
  to {
    transform: translateX(5px);
    -ms-transform: translateX(5px);
  }
}

@-o-keyframes shake {
  from {
    transform: translateX(-5px);
    -ms-transform: translateX(-5px);
  }
  to {
    transform: translateX(5px);
    -ms-transform: translateX(5px);
  }
}

@keyframes shake {
  from {
    transform: translateX(-5px);
    -ms-transform: translateX(-5px);
  }
  to {
    transform: translateX(5px);
    -ms-transform: translateX(5px);
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0);
    -ms-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    transform: rotate(0);
    -ms-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  from {
    transform: rotate(0);
    -ms-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
    -ms-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}
