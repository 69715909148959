.option-badge {
  border: 1px solid var(--color-gray200);
  border-radius: var(--radius);
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.option-badge p {
  font-size: 1.4rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.option-badge svg {
  margin: 0.8rem auto;
}

.selected-option-badge {
  composes: option-badge;
  border: 1px solid var(--color-green500);
  background-color: var(--color-green50);
}
