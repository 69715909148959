@import 'css/variables';

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: $size28;
  grid-template-columns: $size320 auto;
  grid-template-rows: $size324;
  width: 100%;
  justify-content: stretch;

  @media (max-width: 1366px) {
    display: block;
  }
}
