@import 'css/variables';

.base {
  display: inline-block;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.iconLeft {
  float: left;
  margin-right: $size8;
}

.iconRight {
  float: right;
  margin-left: $size8;
}

.iconMediumPosition {
  margin-top: $size4;
}
