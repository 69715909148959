@import 'css/variables';

.radio {
  margin: 0 0 $size12 0;
  cursor: pointer;
}

.input {
  position: relative;
  float: left;
  width: $size20;
  height: $size20;
  cursor: pointer;
  margin-right: $scale;
  background: none;
  border: 1px solid $c-gray500;
  background-size: 70%;
  background-position: center center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;

  &:checked {
    border: none;
    background-color: $c-green;
    transition: all 0.2s ease-in-out;
  }
}

.label {
  cursor: pointer;
  margin-top: 0.325em;
  text-transform: none !important;
}
