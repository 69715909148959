@import 'css/variables';

.base {
  color: $c-primary;
  height: 100%;
}

.underline {
  text-decoration: underline;
}
