@import 'css/variables';

.base {
  display: inline-block;
  margin: 0 $size4;
  padding: $size4 0.65em;
  color: white;
  background-color: $c-purple;
  font-size: $size12;
  text-align: center;
  border-radius: $radius;
}

.closable {
  padding: $size2 $size8 $size2 $size24;
  color: $c-gray700;
  font-size: $size14;
  line-height: $size20;
  border: 1px solid $c-gray100;
  background-color: $c-gray50;
  border-radius: $radius;

  &:before {
    content: url('/assets/icons/cross.svg');
    position: absolute;
    top: 50%;
    left: $size6;
    transform: translate(0, -45%);
  }
}

.blue {
  background-color: $c-blue;
}

.red {
  background-color: $c-red;
}

.green {
  background-color: $c-green;
}

.primary50 {
  background-color: $c-primary50;
  color: $c-primary600;
}

.danger50 {
  background-color: $c-red50;
  color: #d92453;
}

.orange {
  background-color: $c-orange;
}
