.toolbar {
  position: fixed;
  bottom: 3.1rem;
  right: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.add-element-window {
  position: absolute;
  bottom: 15.2rem;
  right: 2.4rem;
  width: 42rem;
  height: 14rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray100);
  border-radius: var(--radius);
  box-shadow: 0px 4px 6px -2px #10182008;
  box-shadow: 0px 12px 16px -4px #10182014;
  padding: 2.4rem;
  z-index: 2;
}

.customize-title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: var(--color-secondary900);
  margin: 0;
}

.customize-container-options {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.customize-colors input {
  width: 100%;
  margin-top: 0.8rem;
}

.option-badge-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.8rem;
}

.advanced-options-container {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
