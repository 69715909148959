@import 'css/variables';

.container {
  position: relative;
  margin-top: -$size25;
}

.titleContainer {
  display: flex;
  justify-content: center;
  gap: $size8;
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.comparisonGaugeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -$size25;
  height: $size325;
}

.gaugeContainer {
  width: 100%;
  position: relative;
}
