@import 'css/variables';

.paginate {
  display: inline-block;
}

.button {
  float: left;
}

.counter {
  float: left;
  margin: -0.3rem 0.8rem;
  margin-top: 0.2rem;
  font-size: 1.4rem;
}
