@import 'css/variables';

.base {
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  border-radius: $radius;
  white-space: nowrap;
}

.loading {
  &:after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    right: $size16;
    top: $size14;
    z-index: 3;
    background-size: contain;
    background-image: url('./icons/ico-loader.svg');
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
  }
}

.btn {
  display: inline-block;
  color: $c-white;
  font-size: $size16;
  line-height: $size24;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: $size10 $size16;
  background-color: $c-primary;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: darken($c-green, 6%);
    transition: all 0.4s ease-in-out;
  }
}

.red {
  background-color: $c-red;

  &:hover {
    background-color: darken($c-red, 5%) !important;
    border-color: darken($c-red, 5%) !important;
  }
}

.blue {
  background-color: $c-blue;

  &:hover {
    background-color: darken($c-blue, 5%) !important;
    border-color: darken($c-blue, 5%) !important;
  }
}

.white {
  background-color: $c-white;
  color: $c-gray900;
  border: 1px solid $c-white;

  &:hover {
    background-color: $c-white;
    border: 1px solid $c-gray100;
  }
}

.softPrimary {
  background-color: $c-primary50 !important;
  border: 1px solid $c-primary !important;
  color: $c-primary800 !important;

  &:hover {
    background-color: darken($c-primary50, 5%) !important;
    border-color: darken($c-primary, 5%) !important;
  }
}

.primary {
  background-color: $c-primary;

  &:hover {
    background-color: darken($c-primary, 5%) !important;
    border-color: darken($c-primary, 5%) !important;
  }
}

.dark {
  background-color: $c-primary800;
  color: $c-white;

  &:hover {
    background-color: darken($c-primary800, 5%) !important;
    border-color: darken($c-primary800, 5%) !important;
  }
}

.darkPrimary {
  background-color: $c-primary800;
  color: $c-primary;
  border: 2px solid $c-primary;

  &:hover {
    transition: all 0.4s ease-in-out;
    background-color: darken($c-primary800, 2%) !important;
    border-color: darken($c-primary, 5%) !important;
  }
}

.warning {
  background-color: $c-yellow500;
  color: $c-primary800;

  &:hover {
    background-color: darken($c-yellow500, 5%) !important;
    border-color: darken($c-yellow500, 5%) !important;
  }
}

.gray {
  background-color: $c-gray200;
  color: $c-gray800;

  &:hover {
    background-color: darken($c-gray200, 5%) !important;
    border-color: darken($c-gray200, 5%) !important;
  }
}

.small {
  font-size: $size14;
  line-height: $size20;
  padding: $size8 $size16;
  height: $size36;
}

.xsmall {
  font-size: $size14;
  line-height: $size20;
  padding: $size4 $size8;
  height: $size28;
}

.xxsmall {
  font-size: $size12;
  line-height: $size18;
  padding: $size3 $size8;
  height: $size24;
}

.big {
  @media (min-width: $mobile) {
    font-size: $scale1;
  }
}

.outline {
  color: $c-text;
  background: transparent;
  border: 1px solid $c-border-dark;

  &:hover {
    color: white;
    border-color: $c-red;
    background: $c-red;
  }
}

.outlinePrimary {
  color: $c-primary;
  background: transparent;
  border: 1px solid $c-primary;

  &:hover {
    color: white;
    border-color: $c-primary;
    background: $c-primary;

    svg {
      color: $c-white;
      transition: all 0.4s ease-in-out;
    }
  }
}

.outlineDark {
  background-color: $c-white;
  border: 1px solid $c-primary600;
  color: $c-primary600;

  &:hover {
    background-color: darken($c-primary600, 7%);
    transition: all 0.4s ease-in-out;
    color: $c-white;

    &svg {
      color: $c-white;
      transition: all 0.4s ease-in-out;
    }
  }
}

.textOnly {
  color: $c-text;
}

.primaryText {
  color: $c-primary;
}

.iconButton {
  display: inline-block;
}

.fullWidth {
  width: 100%;

  > svg,
  image {
    float: none;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.outlineRed {
  border: 1px solid $c-red500;
  background: $c-red50;
  color: $c-red500;
  transition: none;

  &:hover {
    background-color: adjust-hue($c-red50, 0);
    border-color: adjust-hue($c-red500, 0);
    transition: none;
  }
}

.fluid {
  height: 100%;
  width: 100%;
}

.blocked {
  pointer-events: none;
}
