@import 'css/variables';

.container {
  width: 100%;
  height: 100%;
  padding: 0 $mainContentMargin;
  margin: auto;
  position: relative;

  @media (max-width: $mobile) {
    padding: 0 $size20;
  }

  @media (max-width: 48rem) {
    padding: 0 $size16;
  }
}
