.react-select-container label {
  margin-bottom: 0.8rem;
  display: block;
}

.control {
  border-radius: var(--radius) !important;
  border: 1px solid var(--color-gray200) !important;
  padding: 0 0.6rem;
  cursor: pointer !important;
  min-height: auto !important;
  outline: none !important;
  box-shadow: none !important;
}

.control img {
  width: 1.6rem;
  height: 1.6rem;
}

.control-active {
  box-shadow: 0px 2px 3px 0px rgba(16, 24, 32, 0.0509803922) !important;
}

.container {
  outline: none !important;
  border: none !important;
}

.separator {
  display: none;
}

.single-value {
  font-size: 1.4rem !important;
  line-height: 2rem !important;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: var(--color-gray900) !important;
  font-weight: 500;
  margin-left: 0;
  margin-right: 0;
}

.dropdown-indicator {
  padding: 0rem;
}

.indicators-container {
  margin-right: 0.6rem;
}

.value-container {
  font-size: 1.4rem !important;
  line-height: 2rem !important;
  padding: 0.2rem 0.6rem 0.2rem 0.6rem !important;
}

.menu {
  width: max-content !important;
  min-width: 100% !important;
  border-radius: 0.6rem !important;
  margin-top: 0.8rem !important;
  z-index: 4 !important;
}

.option {
  background-color: var(--color-bg) !important;
  cursor: pointer !important;
  font-size: 1.4rem !important;
  line-height: 2rem !important;
  color: var(--color-gray900) !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 0.6rem !important;
  white-space: nowrap;
}

.option img {
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.disabled {
  background-color: var(--color-gray50) !important;
  color: var(--color-gray100) !important;
  opacity: 50% !important;
}

.small {
  height: 3.2rem !important;
}

.medium {
  height: 3.6rem;
}

.large {
  height: 4rem;
}

.primary {
  border: 1px solid var(--color-primary) !important;
}

.primary500 {
  border: 0.1rem solid var(--color-primary500) !important;
}

.green500 {
  border: 1px solid var(--color-green500) !important;
  background-color: var(--color-green25) !important;
}
