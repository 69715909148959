/* colours */
$c-bg: #f4f4f4;
$c-bg-additional: #ffffff;
$c-text: #222222;
$c-border: #e4e7ed;
$c-border-dark: #dadee0;

// gray
$c-gray900: #101828;
$c-gray800: #1d2939;
$c-gray700: #344054;
$c-gray600: #475467;
$c-gray500: #667085;
$c-gray400: #98a2b3;
$c-gray300: #d0d5dd;
$c-gray200: #eaecf0;
$c-gray100: #f2f4f7;
$c-gray75: #ececec;
$c-gray50: #f9fafb;
$c-gray25: #fcfcfd;

$c-primary900: #161616;
$c-primary800: #1d1d1d;
$c-primary700: #262626;
$c-primary600: #303030;
$c-primary500: #353535;
$c-primary: #353535;
$c-primary400: #5d5d5d;
$c-primary300: #787878;
$c-primary200: #a2a2a2;
$c-primary100: #c0c0c0;
$c-primary50: #ebebeb;
// primary (our company green)
// $c-primary600: #42ac8a;
// $c-primary: #48bd98; // primary/green500 in DS
// $c-primary500: #48bd98;
// $c-primary400: #6dcaad;
// $c-primary50: #edf8f5;

// green
$c-green900: #0e4725;
$c-green800: #135d30;
$c-green700: #18783e;
$c-green600: #1f9a4f;
$c-green500: #22a957;
$c-green400: #4eba79;
$c-green300: #6bc58e;
$c-green200: #99d7b2;
$c-green100: #bae4cb;
$c-green50: #e9f6ee;
$c-green25: #f4fbf6;

// danger
$c-red500: #e61c50;
$c-red300: #ee678a;
$c-red50: #fde8ee;

// yellow
$c-yellow500: #fcc53e;
$c-yellow50: #fff9ec;

// social media
$c-facebook: #3b5998;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #d73a17;

$c-white: #ffffff;
$c-off-white: #f5f5f5;
$c-red: #d95565;
$c-green: #10b981;
$c-blue: #73b0f4;
$c-orange: #f0aa61;
$c-purple: #6363ac;
