.builder-layout {
  padding: 0 0 0 var(--sidebar-width);
  min-height: 100vh;
  background-color: var(--color-white);
  display: grid;
}

@media (max-width: 1024px) {
  .builder-layout {
    padding: 0;
    min-height: calc(100vh - 8rem);
  }
}

.bg-stain-top {
  position: fixed;
  top: -3rem;
  right: -22rem;
  width: 67rem;
  height: 67rem;
  pointer-events: none;
}

.bg-stain-bottom {
  position: fixed;
  bottom: -22rem;
  left: -7.6rem;
  width: 6.1rem;
  height: 6.1rem;
  pointer-events: none;
}
