@import 'css/variables';

.app {
  flex: 1;
  padding: $size24 0 $size24 $sidebarWidth;
  min-height: calc(100vh - $footerHeight);
  background-color: $c-bg-additional;

  @media (max-width: $mobile) {
    padding: $size24 0;
  }
}

.bgStainTop {
  position: fixed;
  top: -$size30;
  right: -$size220;
  width: $size670;
  height: $size670;
  pointer-events: none;
}

.bgStainBottom {
  position: fixed;
  bottom: -$size220;
  left: -$size76;
  width: $size610;
  height: $size610;
  pointer-events: none;
}
