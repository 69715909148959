.slide-button {
  height: 16.8rem;
  min-width: 34.6rem;
  border-radius: var(--radius);
  background-color: var(--color-primary50);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2348BD98FF' stroke-width='4' stroke-dasharray='4%2c 16' stroke-dashoffset='60' stroke-linecap='square'/%3e%3c/svg%3e");
}

@media (max-width: 480px) {
  .slide-button {
    height: 16.8rem;
    min-width: auto;
  }
}

.slide-button p {
  width: 35.6rem;
}

@media (max-width: 480px) {
  .slide-button p {
    width: 100%;
    padding: 0 0.8rem;
  }
}
