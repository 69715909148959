@import 'css/variables';

.base {
  padding: $size16 $size8;
  background: $c-white;
  border-radius: $radius;
  border: 1px solid $c-gray75;
  text-align: center;
  position: relative;
  min-width: $size150;
  min-height: $size150;
}

.name-container {
  display: flex;
  align-items: center;
  gap: $size4;
  margin-top: 1.4rem;
  justify-content: center;
}

.loader-container {
  height: 8.8rem;
}

.name {
  font-size: $size14;
  line-height: $size20;
  color: $c-gray700;
  white-space: nowrap;
}

.valuesContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.valuesContainerWithTrend {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: $size72;
  margin-top: $size12;
  position: relative;
}

.valueRow {
  display: flex;
  align-items: center;
  margin-top: $size8;
}

.logo {
  width: $size20;
  height: $size20;
  margin-right: $size8;
  padding: $size2;
  border-radius: $size4;
  background-color: $c-gray50;
}

.value {
  font-size: $size18;
  line-height: $size28;
  font-weight: 600;
  text-align: left;
  transition: transform 1s ease-in-out;
  width: 100%;
  word-wrap: break-word;

  @media (max-width: 1600px) {
    font-size: $size16;
    line-height: $size24;
  }

  @media (max-width: 1440px) {
    font-size: $size14;
    line-height: $size20;
  }

  @media (max-width: 1366px) {
    font-size: $size18;
    line-height: $size28;
  }

  @media (max-width: 768px) {
    font-size: $size14;
    line-height: $size20;
  }

  @media (max-width: 640px) {
    font-size: $size16;
    line-height: $size24;
  }
}

.emptyBlock {
  display: inline-block;
  margin-bottom: $size18;
}

.leftRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $size16;
  position: absolute;
  right: calc(50% + $size24);

  @media (max-width: 1600px) {
    right: calc(50% + $size16);
  }

  @media (max-width: 1440px) {
    right: calc(50% + $size12);
  }

  @media (max-width: 1366px) {
    right: calc(50% + $size24);
  }

  @media (max-width: 768px) {
    right: calc(50% + $size12);
  }

  @media (max-width: 640px) {
    right: calc(50% + $size24);
  }
}

.rightRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $size16;
  position: absolute;
  left: calc(50% + $size24);

  @media (max-width: 1600px) {
    left: calc(50% + $size16);
  }

  @media (max-width: 1440px) {
    left: calc(50% + $size12);
  }

  @media (max-width: 1366px) {
    left: calc(50% + $size24);
  }

  @media (max-width: 768px) {
    left: calc(50% + $size12);
  }

  @media (max-width: 640px) {
    left: calc(50% + $size24);
  }
}

.message {
  font-size: $size24;
  line-height: $size32;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  transition: transform 1s ease-in-out;
  width: 100%;
  word-wrap: break-word;
  padding: 0 $size6;
  white-space: nowrap;
}

.line {
  background-color: $c-gray300;
  height: 90%;
}

.leftColumn {
  text-align: right;
  margin-right: $size24;

  @media (max-width: 1600px) {
    margin-right: $size16;
  }

  @media (max-width: 1440px) {
    margin-right: $size12;
  }

  @media (max-width: 1366px) {
    margin-right: $size24;
  }

  @media (max-width: 768px) {
    margin-right: $size12;
  }

  @media (max-width: 640px) {
    margin-right: $size24;
  }
}

.rightColumn {
  text-align: left;
  margin-left: $size24;

  @media (max-width: 1600px) {
    margin-left: $size16;
  }

  @media (max-width: 1440px) {
    margin-left: $size12;
  }

  @media (max-width: 1366px) {
    margin-left: $size24;
  }

  @media (max-width: 768px) {
    margin-left: $size12;
  }

  @media (max-width: 640px) {
    margin-left: $size24;
  }
}
