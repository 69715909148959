@import 'css/variables';

.newsBannerContainer {
  border-radius: $size8;
  position: relative;
  cursor: pointer;
  border: 1px solid $c-white;
  min-width: $size320;

  @media (max-width: 1366px) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media (max-width: 640px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 480px) {
    min-width: auto;

    img {
      padding: $size16;
      width: 100%;
    }
  }
}

.newsBannerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $size8;
}

.newsBannerContent {
  width: $size285;
}

.opacity-cover {
  position: relative;
  opacity: 0.4;
  background-color: $c-white;
  height: 100%;
  width: 100%;
}
