@import 'css/variables';

.home {
  flex: 1;
  display: grid;
  padding: $size48 $size48 $size48 $size276;
  // min-height: max(calc(100vh - ($footerHeight + $headerHeight)), $size500);
  min-height: 100vh;
  background-color: $c-bg;

  @media (max-width: $mobile) {
    padding: $size24;
  }
}
