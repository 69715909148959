.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  background-color: var(--color-white);
  height: 6.4rem;
  gap: 1.6rem;
  border-top: 1px solid var(--color-gray75);
}
