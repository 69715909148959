.react-date-picker {
  display: inline-flex;
  position: relative;
  background-color: #ffffff;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #ffffff;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 0.1rem solid #cbcbcb !important;
  border-radius: 0.6rem !important;
  height: 3.8rem !important;
  padding: 0 0.8rem;
}

.react-date-picker__inputGroup {
  min-width: calc((0.4rem * 3) + 0.8rem * 8 + 0.35rem * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 0.2rem;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 0.1rem 0;
  white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
  color: #333333;
}

.react-date-picker__inputGroup__input {
  min-width: 0.8rem;
  height: 100%;
  position: relative;
  padding: 0 0.1rem;
  border: 0;
  background: none;
  /* color: currentColor; */
  /* color: #3c3c3c; */
  color: #333333 !important;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input:focus-visible {
  outline: 0.2rem solid var(--color-primary);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.8rem;
  padding-left: calc(0.1rem + 0.8rem);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 0.4rem 0.6rem;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--color-primary) !important;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 35rem;
  max-width: 100vw;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}
