.content-container {
  min-width: 0.2rem;
}

.content {
  display: flex;
  align-items: center;
}

.logo-size-m {
  width: 1.6rem;
  height: 1.6rem;
}

.logo-size-xl {
  width: 2.4rem;
  height: 2.4rem;
}

.info-text {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--color-gray500);
}

.content-gap-m {
  composes: content;
  gap: 0.6rem;
}

.content-gap-xl {
  composes: content;
  gap: 1rem;
}

.frame {
  border: 1px solid var(--color-gray100);
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-gray800);
  padding: 0.6rem 0.8rem;
  display: inline-flex;
  align-items: center;
  border-radius: var(--radius);
  gap: 0.6rem;
  white-space: nowrap;
}
