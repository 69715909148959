.general-container {
  display: grid;
  grid-template-columns: 19.5rem 19.5rem 19.5rem 19.5rem;
  column-gap: 2.4rem;
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
  width: 85.2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.6rem;
    width: 57.6rem;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    width: calc(100vw - 9.6rem);
  }
}

.address-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
}

.logo-outer-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 2.4rem;
}

.logo-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    width: auto;
  }
}

.logo-settings-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.logo-image {
  max-width: 6.6rem;
  max-height: 6.6rem;
}
