@import 'css/variables';

.bgChartImage {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contentContainer {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.mainText {
  font-size: $size18;
  line-height: $size28;
  font-weight: 600;
  color: $c-gray900;

  @media (max-width: 640px) {
    font-size: $size16;
    line-height: $size24;
  }
}

.additionalText {
  font-size: $size18;
  line-height: $size28;
  font-weight: 400;
  color: $c-gray600;
  padding-bottom: $size12;

  @media (max-width: 640px) {
    font-size: $size16;
    line-height: $size24;
  }
}
