@import 'css/variables';

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.dappTable {
  border-collapse: collapse;
  width: 100%;
}

.dappTable td:nth-child(2) {
  min-width: $size150;
  width: 15%;
}

.dappTable td:nth-child(5) {
  width: 40%;
}

.head {
  font-size: $size12;
  line-height: $size18;
  color: $c-gray600;
  font-weight: 500;
  text-align: left;

  th {
    padding: $size12 $size24;

    @media (max-width: 640px) {
      padding-left: $size16;
      padding-right: $size16;
    }
  }
}

.row {
  height: $size72;
  text-align: left;
  border-top: 1px solid #eaecf0;
  font-size: $size16;
  line-height: $size24;
  font-weight: 400;
  color: $c-gray800;
}

.dapp-column-container {
  padding: 0 2.4rem;

  @media (max-width: 640px) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.row:hover {
  background-color: #faf9f9;
}

.numberCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size12;
  min-width: $size70;
  width: 5%;
}

.dAppCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size12;
  min-width: $size150;
  width: 30%;

  @media (min-width: $mobile) {
    white-space: nowrap;
  }
}

.blockchainFrame {
  border: 1px solid $c-gray100;
  font-size: $size12;
  line-height: $size18;
  color: $c-gray800;
  padding: $size6 $size8;
  display: inline-flex;
  align-items: center;
  border-radius: $radius;
  gap: $size6;
  white-space: nowrap;
}

.growthIndexCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;
  min-width: $size96;
  width: 10%;
}

.changeRankContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size4;
}

.progressRank {
  color: $c-green500;
}

.regressRank {
  color: $c-red500;
}

.growthIndexTimelineColumnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $size8;

  @media (max-width: $mobile) {
    min-width: $size150;
  }
}

.dApplogo {
  max-height: $size24;
  max-width: $size24;
}

.blockchainLogo {
  max-height: $size16;
  max-width: $size16;
}

.announcementContainer {
  margin: $size32 auto;
}

.announcement {
  text-align: center;
}
