.sc-content-container {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
  width: 86rem;
  max-height: 28rem;
  overflow: auto;

  @media (max-width: 1024px) {
    width: 57.6rem;
    max-height: none;
    margin-bottom: 0rem;
  }

  @media (max-width: 640px) {
    width: calc(100vw - 9.6rem);
  }
}

.upload-sc-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @media (max-width: 1024px) {
    gap: 0rem;
    margin-top: -1.6rem;
    margin-bottom: 1.6rem;
  }
}

.numeration-column {
  border-right: 1px solid var(--color-gray100);
  width: 1.8rem;
}

.sc-list-row {
  display: grid;
  grid-template-columns: 1.8rem 18rem 39.1rem 21.5rem;
  column-gap: 1.6rem;
  margin-bottom: 1.2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }
}

.no-sc-container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.close-button-container {
  height: 3.8rem;
  width: 100%;
  display: flex;
  gap: 1.2rem;
}
