@import 'css/variables';

.bigLabel {
  display: inline-block;
  font-size: $size14;
  line-height: $size20;
  color: $c-gray600;
  padding: $size2 $size8 $size2 $size28;
  border: 1px solid $c-gray500;
  background-color: $c-gray100;
  border-radius: $radius;
  position: absolute;
  left: 3%;

  @media (max-width: 640px) {
    margin-top: $size48;
    font-size: $size14;
    line-height: $size16;
  }

  &:before {
    content: url('/assets/icons/growth-black.svg');
    position: absolute;
    top: $size16;
    left: $size8;
    transform: translate(0, -50%);
  }
}

.labelsContainer {
  display: flex;
  justify-content: flex-start;
  gap: $size8;
  position: absolute;
  left: 3%;

  @media (max-width: 1024px) {
    margin-top: $size60;
    left: 6%;
  }

  @media (max-width: 640px) {
    margin-top: 4rem;
  }
}

.smallLabel {
  display: flex;
  align-items: center;
  border-radius: $size4;
  padding: 0 $size12;

  p {
    font-size: $size16;
    font-weight: 600;
    color: $c-gray900;
  }

  img {
    margin-right: $size8;
    height: $size16;
    width: auto;
  }
}

.primary {
  border: 1px solid $c-primary;
}

.dark {
  border: 1px solid $c-primary500;
}
