@import 'css/variables';

.bigLabel {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $c-gray600;
  padding: 0.1rem 0.8rem 0.1rem 2.8rem;
  border: 1px solid $c-gray500;
  background-color: $c-gray100;
  border-radius: $radius;
  position: absolute;
  left: 3%;

  @media (max-width: 640px) {
    margin-top: 4.8rem;
  }

  &:before {
    content: url('/assets/icons/growth-black.svg');
    position: absolute;
    top: 1.4rem;
    left: 0.8rem;
    transform: translate(0, -50%);
  }
}

.labelsContainer {
  display: flex;
  justify-content: flex-start;
  gap: $size12;
  position: absolute;
  left: 3%;

  @media (max-width: 1024px) {
    margin-top: 0;
    left: 6%;
  }

  @media (max-width: 640px) {
    margin-top: 3.2rem;
  }
}

.smallLabel {
  display: flex;
  align-items: center;
  border: 1px solid $c-gray500;
  background-color: $c-gray100;
  border-radius: $radius;
  padding: $size2 $size8;

  p {
    font-size: $size14;
    font-weight: 600;
    color: $c-gray900;
  }

  img {
    margin-right: $size8;
    height: $size16;
    width: auto;
  }
}

.primary {
  border: 1px solid $c-gray500;
}

.dark {
  border: 1px solid $c-gray500;
}
