.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
}

.checkbox-container input[type='checkbox'] {
  accent-color: var(--color-primary500);
  cursor: pointer;
}
