@import 'css/variables';

.footer {
  background-color: $c-bg-additional;
  border-top: 1px solid $c-border;
  padding: $size24 0 $size24 $sidebarWidth;

  @media (max-width: $mobile) {
    padding: $size24 0;
  }
}

.copyright {
  color: $c-gray600;
  font-size: $size12;
  line-height: $size18;

  @media (max-width: $mobile) {
    padding: $size8 0 0 0;
  }
}

.nav {
  height: $size20;
  display: inline-block;

  @media (max-width: $mobile) {
    height: auto;
  }
}

.link {
  color: $c-gray600 !important;
  font-size: $size14;
  line-height: $size20;
  text-decoration: none;
  margin-right: $size16;

  @media (max-width: $mobile) {
    display: block;
    padding-bottom: $size16;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
