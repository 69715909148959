.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}

@media (max-width: 640px) {
  .title-container {
    flex-direction: column;
    gap: 1.6rem;
  }
}
