@import 'css/variables';

.bar {
  width: 100%;
  text-align: center;
  margin-bottom: $size10;

  @media (max-width: 640px) {
    margin-bottom: $size50;
  }
}
