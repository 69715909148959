@import 'css/variables';

.base {
  height: 34.5rem;

  h1 {
    font-size: $size40;
    line-height: $size48;
    font-weight: 700;
    color: $c-primary800;

    span {
      color: $c-red300;
    }
  }

  img {
    margin-bottom: $size32;
  }

  p {
    margin-top: $size8;
  }

  button {
    margin: $size32 auto 0 auto;

    svg {
      top: 3px;
    }
  }
}
