@import 'css/variables';

.avatar-container {
  height: $size40;
  width: $size40;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-text {
  color: $c-white;
  font-size: 18px;
}
