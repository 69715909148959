@import 'css/variables';

.bannersContainer {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: $size150;
  column-gap: $size24;
  margin-top: $size16;
  margin-bottom: $size24;

  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: $size150 $size150;
    grid-row-gap: $size24;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto $size150 $size150;
  }
}

.bannersContainer::before {
  content: url('/features/gi/shared/assets/images/right-bg-donat.png');
  position: absolute;
  top: 0rem;
  right: 28%;
  opacity: 0.2;

  @media (max-width: 1366px) {
    top: 0rem;
    right: 8%;
  }
}
