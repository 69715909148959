@import 'css/variables';

.section {
  display: flex;
  justify-content: space-between;
  gap: $size16;
  margin-top: $size12;
  margin-bottom: $size24;

  @media (max-width: 1200px) {
    display: grid;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  gap: $size16;

  @media (max-width: 1200px) {
    display: grid;
  }
}
