@import 'css/variables';

.container {
  background-color: $c-primary800;
  border-radius: $radius;
  text-align: left;
  padding: $size16 $size24;
  width: 100%;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.8rem;
  margin-top: 3.1rem;
}

.title {
  color: $c-gray500;
  font-size: $size14;
  line-height: $size20;
}

.value {
  position: relative;
  color: $c-primary500;
  font-size: $size24;
  line-height: $size20;
  margin-top: $size11;
  margin-bottom: $size11;
}

.green50 {
  background-color: $c-green50;
}

.yellow50 {
  background-color: $c-yellow50;
}
