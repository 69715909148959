.label {
  color: var(--color-gray800);
  font-size: 1.4rem;
  line-height: 2rem;
}

.error {
  color: var(--color-red500);
  z-index: 2;
  margin-left: -1.6rem;
}

.required:after {
  content: '*';
  color: var(--color-red500);
  margin-left: 0.35em;
  font-size: 1.6rem;
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
  color: #000000;
  font-weight: 400;
}

.small {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
