@import 'css/variables';

.container {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0;
  width: 100%;

  span {
    text-align: center;
    font-size: $size12;
    line-height: $size18;
    font-weight: 400;
    color: $c-gray500;
    padding-left: $size18;
  }

  .legendOptionContainer {
    position: relative;
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .low {
    width: $size12;
    height: $size12;
    background: $c-red500;
    border-radius: 50%;
  }

  .lowMid {
    width: $size12;
    height: $size12;
    background: #ff640c;
    border-radius: 50%;
  }

  .mid {
    width: $size12;
    height: $size12;
    background: $c-yellow500;
    border-radius: 50%;
  }

  .midHigh {
    width: $size12;
    height: $size12;
    background: $c-green200;
    border-radius: 50%;
  }

  .high {
    width: $size12;
    height: $size12;
    background: $c-green500;
    border-radius: 50%;
  }
}
